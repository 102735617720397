import { type IconProps } from '.';

export const TransferIcon = ({ color = '#8F9BB3', ...props }: IconProps) => {
  return (
    <svg
      className="transfer-icon"
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill={color}
        d="M.89 4.375h11.559l-1.423 1.05a.879.879 0 00-.346.576.861.861 0 00.168.649.887.887 0 00.712.35c.192 0 .38-.061.533-.175L15.65 4.2a.88.88 0 00.35-.695.864.864 0 00-.35-.696L12.218.185a.9.9 0 00-1.25.153.865.865 0 00.156 1.229l1.396 1.059H.89a.897.897 0 00-.63.256.868.868 0 000 1.237.897.897 0 00.63.256zM15.11 10.625H3.552l1.423-1.05a.865.865 0 00.178-1.225.9.9 0 00-1.245-.175L.35 10.8a.878.878 0 00-.35.696.864.864 0 00.35.695l3.432 2.625a.899.899 0 00.934.094.888.888 0 00.311-.251.865.865 0 00-.151-1.225L3.48 12.375h11.63a.897.897 0 00.63-.256.868.868 0 000-1.238.897.897 0 00-.63-.256z"
      />
    </svg>
  );
};
