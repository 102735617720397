const colors = {
  // main colors
  primary: '#0062DC',
  white: '#ffffff',
  black: '#181921',

  // secondary colors
  cyan: '#00D4FF',
  orange: '#FC6D20',
  graphite: '#343647',
  gray: '#8F9BB3',
  darkGrey: '#495057',

  pureBlack: '#000',

  unsuccess: '#DD3535',
};

export const theme = {
  colors,
  fonts: {
    base: "'Open Sans', sans-serif",
  },
  fontStyles: {
    normal: 'normal',
    italic: 'italic',
  },
  fontWeights: {
    regular: 400,
    semiBold: 600,
    bold: 700,
  },
  borderRadius: {
    sm: '4px',
    md: '8px',
    lg: '16px',
    circular: '50%',
    full: '99999px',
  },
} as const;
