import { type CSSProperties } from '@stitches/react';
import { type ComponentProps, type ReactNode } from 'react';

import { theme } from '../../styles/theme';
import { InfoIcon } from '../Icons';
import { Tooltip } from '../Tooltip';

import * as S from './styles';

const requiredPropsMessageError =
  "Tabs must be used only with a `globalChildren` prop or only `children` prop's for each item inside the items array";

export type TabItem = {
  id: string;
  label: string;
  helperText?: string;
  disabled?: boolean;
  children?: ReactNode;
};

export interface TabsProps extends ComponentProps<typeof S.Root> {
  items: TabItem[];
  globalChildren?: ReactNode;
  tabsMaxWidth?: CSSProperties['maxWidth'];
  tabsListBorderBottom?: CSSProperties['borderBottom'];
  borderHighlightWidth?: CSSProperties['borderWidth'];
}

type GetTabItemTriggerContentProps = {
  tabsMaxWidth: TabsProps['tabsMaxWidth'];
  borderHighlightWidth: TabsProps['borderHighlightWidth'];
  item: TabItem;
};

const getTabItemTriggerContent = ({
  tabsMaxWidth,
  borderHighlightWidth,
  item,
}: GetTabItemTriggerContentProps) => {
  const triggerStyles = Object.assign(
    {},
    tabsMaxWidth && {
      maxWidth: tabsMaxWidth,
      width: tabsMaxWidth,
    },
    borderHighlightWidth && {
      '&[data-state="active"]': {
        borderBottomWidth: borderHighlightWidth,
      },
    }
  );

  return item?.helperText ? (
    <Tooltip key={item.label} text={item.helperText} triggerAsChild={false}>
      <S.Trigger disabled={!!item.disabled} value={item.id} css={triggerStyles}>
        {item.label}
        <InfoIcon color={theme.colors.darkGrey} aria-hidden />
      </S.Trigger>
    </Tooltip>
  ) : (
    <S.Trigger
      key={item.label}
      disabled={!!item.disabled}
      value={item.id}
      css={triggerStyles}
    >
      {item.label}
    </S.Trigger>
  );
};

export const Tabs = ({
  items,
  tabsMaxWidth = undefined,
  tabsListBorderBottom = undefined,
  borderHighlightWidth = undefined,
  globalChildren,
  ...props
}: TabsProps) => {
  const isComponentWithoutRequiredProps =
    !globalChildren && items.some(item => !item.children);

  if (isComponentWithoutRequiredProps) {
    throw new Error(requiredPropsMessageError);
  }

  const isComponentWithDuplicatedRequiredProps =
    !!globalChildren && items.every(item => !!item.children);

  if (isComponentWithDuplicatedRequiredProps) {
    throw new Error(`Duplicated props error! ${requiredPropsMessageError}`);
  }

  const listStyles = Object.assign(
    {},
    tabsListBorderBottom && {
      borderBottom: tabsListBorderBottom,
    }
  );

  return (
    <S.Root defaultValue={items[0].id} {...props}>
      <S.List aria-label="Tab content list" css={listStyles}>
        {items.map(item =>
          getTabItemTriggerContent({
            tabsMaxWidth,
            borderHighlightWidth,
            item,
          })
        )}
      </S.List>

      {items.map(item => (
        <S.Content key={item.label} value={item.id}>
          {globalChildren || item?.children}
        </S.Content>
      ))}
    </S.Root>
  );
};
