import { type IconProps } from '.';

export const OpenedEyeIcon = ({ color = '#8F9BB3', ...props }: IconProps) => {
  return (
    <svg
      className="opened-eye-icon"
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.9187 4.33889C13.492 3.59889 11.1454 -0.11444 7.15874 0.00556012C3.47208 0.0988901 1.33874 3.33889 0.758744 4.33889C0.700234 4.44024 0.669434 4.5552 0.669434 4.67223C0.669434 4.78925 0.700234 4.90421 0.758744 5.00556C1.17874 5.73223 3.42541 9.33894 7.35208 9.33894H7.51875C11.2054 9.24554 13.3454 6.00556 13.9187 5.00556C13.9772 4.90421 14.008 4.78925 14.008 4.67223C14.008 4.5552 13.9772 4.44024 13.9187 4.33889ZM7.48541 8.00554C4.61208 8.07224 2.73874 5.61223 2.15208 4.67223C2.81874 3.59889 4.55875 1.40556 7.22541 1.33889C10.0854 1.26556 11.9654 3.73223 12.5587 4.67223C11.872 5.74556 10.152 7.93894 7.48541 8.00554Z"
        fill={color}
      />
      <path
        d="M7.3387 2.3389C6.87721 2.3389 6.42608 2.47574 6.04237 2.73213C5.65866 2.98852 5.35959 3.35294 5.18298 3.7793C5.00638 4.20566 4.96017 4.67482 5.0502 5.12744C5.14023 5.58006 5.36246 5.99582 5.68878 6.32215C6.01511 6.64847 6.43087 6.87074 6.88349 6.96074C7.33611 7.05074 7.80527 7.00454 8.23163 6.82794C8.65799 6.65134 9.02241 6.35228 9.2788 5.96856C9.53514 5.58485 9.67204 5.13372 9.67204 4.67223C9.67204 4.05339 9.42624 3.4599 8.98862 3.02231C8.55103 2.58473 7.95754 2.3389 7.3387 2.3389ZM7.3387 5.67223C7.14092 5.67223 6.94758 5.61358 6.78313 5.5037C6.61868 5.39382 6.49051 5.23764 6.41482 5.05491C6.33913 4.87219 6.31933 4.67112 6.35792 4.47714C6.3965 4.28316 6.49174 4.10498 6.63159 3.96512C6.77145 3.82527 6.94963 3.73003 7.14361 3.69144C7.33759 3.65286 7.53866 3.67266 7.72138 3.74835C7.90411 3.82404 8.06029 3.95221 8.17017 4.11666C8.28005 4.28111 8.3387 4.47445 8.3387 4.67223C8.3387 4.93745 8.23334 5.1918 8.04581 5.37934C7.85827 5.56687 7.60392 5.67223 7.3387 5.67223Z"
        fill={color}
      />
    </svg>
  );
};
