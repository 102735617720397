import { type IconProps } from '.';

export const DoubleArrowRightIcon = ({
  color = '#8F9BB3',
  ...props
}: IconProps) => {
  return (
    <svg
      className="double-arrow-right-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="9"
      viewBox="0 0 10 9"
      fill="none"
      {...props}
    >
      <path
        d="M5.0034 4.20296C5.004 4.31263 4.98302 4.42135 4.94155 4.52288C4.90008 4.62441 4.83898 4.7168 4.76174 4.7946L1.42841 8.128C1.35071 8.2057 1.25847 8.2673 1.15695 8.3093C1.05543 8.3514 0.94663 8.373 0.83674 8.373C0.72686 8.373 0.61805 8.3514 0.51653 8.3093C0.41502 8.2673 0.32277 8.2057 0.24508 8.128C0.16738 8.0503 0.10574 7.958 0.0636902 7.8565C0.0216402 7.755 0 7.6462 0 7.5363C0 7.4264 0.0216402 7.3176 0.0636902 7.2161C0.10574 7.1146 0.16738 7.0223 0.24508 6.9446L3.00341 4.20296L0.35341 1.45296C0.26817 1.3763 0.19966 1.28287 0.15217 1.17853C0.10468 1.07419 0.0792402 0.96117 0.0774202 0.84654C0.0756002 0.73191 0.0974602 0.61814 0.14162 0.51234C0.18578 0.40655 0.25129 0.311 0.33406 0.23168C0.41683 0.15236 0.51508 0.09097 0.62266 0.05135C0.73023 0.01173 0.84483 -0.00527005 0.95928 0.00141995C1.07372 0.00810995 1.18556 0.0383499 1.28779 0.0902299C1.39002 0.14211 1.48044 0.21453 1.55341 0.30296L4.77008 3.63629C4.91701 3.78868 5.0004 3.99129 5.0034 4.20296Z"
        fill={color}
      />
      <path
        d="M10.0034 4.20296C10.004 4.31263 9.983 4.42135 9.9416 4.52288C9.9001 4.62441 9.839 4.7168 9.7617 4.7946L6.4284 8.128C6.3507 8.2057 6.2585 8.2673 6.157 8.3093C6.0554 8.3514 5.9466 8.373 5.8367 8.373C5.7269 8.373 5.6181 8.3514 5.5165 8.3093C5.415 8.2673 5.3228 8.2057 5.2451 8.128C5.1674 8.0503 5.1057 7.958 5.0637 7.8565C5.0216 7.755 5 7.6462 5 7.5363C5 7.4264 5.0216 7.3176 5.0637 7.2161C5.1057 7.1146 5.1674 7.0223 5.2451 6.9446L8.0034 4.20296L5.3534 1.45296C5.2682 1.3763 5.1997 1.28287 5.1522 1.17853C5.1047 1.07419 5.0792 0.96117 5.0774 0.84654C5.0756 0.73191 5.0975 0.61814 5.1416 0.51234C5.1858 0.40655 5.2513 0.311 5.3341 0.23168C5.4168 0.15236 5.5151 0.09097 5.6227 0.05135C5.7302 0.01173 5.8448 -0.00527005 5.9593 0.00141995C6.0737 0.00810995 6.1856 0.0383499 6.2878 0.0902299C6.39 0.14211 6.4804 0.21453 6.5534 0.30296L9.7701 3.63629C9.917 3.78868 10.0004 3.99129 10.0034 4.20296Z"
        fill={color}
      />
    </svg>
  );
};
