import { type IconProps } from '.';

export const TagIcon = ({ color = '#8F9BB3', ...props }: IconProps) => {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.4 2.60001V1.93334C10.4 1.57972 10.2595 1.24058 10.0095 0.990526C9.7595 0.740476 9.4203 0.600006 9.0667 0.600006H3.73336C3.37974 0.600006 3.0406 0.740476 2.79055 0.990526C2.5405 1.24058 2.40002 1.57972 2.40002 1.93334V2.60001C1.86959 2.60001 1.36088 2.81072 0.985815 3.18579C0.610745 3.56087 0.400024 4.06958 0.400024 4.60001V11.9333C0.400024 12.4638 0.610745 12.9725 0.985815 13.3475C1.36088 13.7226 1.86959 13.9333 2.40002 13.9333H10.4C10.9305 13.9333 11.4392 13.7226 11.8142 13.3475C12.1893 12.9725 12.4 12.4638 12.4 11.9333V4.60001C12.4 4.06958 12.1893 3.56087 11.8142 3.18579C11.4392 2.81072 10.9305 2.60001 10.4 2.60001ZM3.73336 1.93334H9.0667V4.60001H3.73336V1.93334ZM11.0667 11.9333C11.0667 12.1101 10.9965 12.2797 10.8714 12.4047C10.7464 12.5298 10.5768 12.6 10.4 12.6H2.40002C2.22321 12.6 2.05364 12.5298 1.92862 12.4047C1.8036 12.2797 1.73336 12.1101 1.73336 11.9333V4.60001C1.73336 4.4232 1.8036 4.25363 1.92862 4.1286C2.05364 4.00358 2.22321 3.93334 2.40002 3.93334V4.60001C2.40002 4.95363 2.5405 5.29277 2.79055 5.54282C3.0406 5.79287 3.37974 5.93334 3.73336 5.93334H9.0667C9.4203 5.93334 9.7595 5.79287 10.0095 5.54282C10.2595 5.29277 10.4 4.95363 10.4 4.60001V3.93334C10.5768 3.93334 10.7464 4.00358 10.8714 4.1286C10.9965 4.25363 11.0667 4.4232 11.0667 4.60001V11.9333Z"
        fill={color}
      />
    </svg>
  );
};
