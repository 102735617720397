import { type IconProps } from '.';

export const ShoppingBagIcon = ({ color = '#8F9BB3', ...props }: IconProps) => {
  return (
    <svg
      className="shopping-bag-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3333 8.88892C12.7189 8.88892 12.2223 9.38667 12.2223 10C12.2223 11.2256 11.2256 12.2223 10 12.2223C8.77442 12.2223 7.77775 11.2256 7.77775 10C7.77775 9.38667 7.28108 8.88892 6.66667 8.88892C6.05225 8.88892 5.55558 9.38667 5.55558 10C5.55558 12.4511 7.54892 14.4444 10 14.4444C12.4511 14.4444 14.4444 12.4511 14.4444 10C14.4444 9.38667 13.9477 8.88892 13.3333 8.88892ZM16.6667 17.7777H3.33333C2.72 17.7777 2.22222 17.2789 2.22222 16.6667V6.66667H17.7777V16.6667C17.7777 17.2789 17.28 17.7777 16.6667 17.7777ZM5.69 2.54778C5.89775 2.34111 6.18333 2.22222 6.47558 2.22222H13.5244C13.8167 2.22222 14.1023 2.34111 14.31 2.54778L16.2067 4.44444H3.79333L5.69 2.54778ZM19.0233 4.11889L15.8811 0.976667C15.2523 0.346667 14.4144 0 13.5244 0H6.47558C5.58558 0 4.74777 0.346667 4.11889 0.976667L0.976667 4.11889C0.346667 4.74777 0 5.58558 0 6.47667V16.6667C0 18.5044 1.49556 20 3.33333 20H16.6667C18.5044 20 20 18.5044 20 16.6667V6.47667C20 5.58558 19.6533 4.74777 19.0233 4.11889Z"
        fill={color}
      />
    </svg>
  );
};
