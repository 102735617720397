import { useEffect, useState, type ElementType, type ReactNode } from 'react';

import { useDocumentTitle } from '../../hooks/use-document-title';
import { NAV_ITEMS } from '../../utils/constants';
import { ExternalIcon, NewIcon, type IconProps } from '../Icons';

import { ItemContent, ItemTrigger } from './Item';
import * as S from './styles';

type DefaultOptions = {
  title: string;
  isNew?: boolean;
  isExternalLink?: boolean;
  isHiddenSubject?: boolean;
};

export type SubItem = DefaultOptions & {
  href: string;
};

export type NavItem = DefaultOptions & {
  href?: string;
  icon: ElementType<IconProps>;
  subItems?: SubItem[];
};

type GetItemContent = {
  item: NavItem;
  isActive: boolean;
};

const getItemContent = ({ item, isActive }: GetItemContent) => {
  const { icon: Icon } = item;

  const props: IconProps = {
    color: isActive ? '#0062DC' : '#8F9BB3',
    'aria-hidden': true,
  };

  const isExternalLinkItem = !!item.isExternalLink;
  const isNewItem = !!item.isNew;

  return (
    <>
      <Icon className="nav-icon" {...props} />
      <span className="nav-title">{item.title}</span>

      {isExternalLinkItem && (
        <ExternalIcon className="nav-extra-icon" {...props} />
      )}
      {isNewItem && <NewIcon className="nav-extra-icon" {...props} />}
    </>
  );
};

type GetSubItemContent = {
  subItem: SubItem;
  isActive: boolean;
};

const getSubItemContent = ({ subItem, isActive }: GetSubItemContent) => {
  const props: IconProps = {
    className: 'nav-extra-icon',
    color: isActive ? '#0062DC' : '#8F9BB3',
    'aria-hidden': true,
  };

  const isExternalLinkSubItem = !!subItem.isExternalLink;
  const isNewSubItem = !!subItem.isNew;

  return (
    <>
      <span className="nav-title">{subItem.title}</span>

      {isExternalLinkSubItem && <ExternalIcon {...props} />}
      {isNewSubItem && <NewIcon {...props} />}
    </>
  );
};

export type DocumentInfo = {
  title: string;
  description: string;
};

export interface NavbarProps {
  isMobile?: boolean;
  mobileNavArea: ReactNode;
}

export const Navbar = ({ isMobile = false, mobileNavArea }: NavbarProps) => {
  const [activeMenuTitle, setActiveMenuTitle] = useState('');
  const [documentInfo, setDocumentInfo] = useState<DocumentInfo>({
    title: '',
    description: '',
  });

  const checkIfIsActiveItem = (item: NavItem) => {
    const isActiveItem = location.pathname === item?.href;

    const isActiveSubItem = item.subItems?.some(
      subItem =>
        location.pathname === subItem.href ||
        location.pathname.includes(subItem.href)
    );

    return isActiveItem || isActiveSubItem;
  };

  const handleActiveNavItem = () => {
    const activeItem = NAV_ITEMS.find(
      navItem =>
        location.pathname === navItem?.href ||
        navItem.subItems?.find(subItem => location.pathname === subItem.href)
    );

    const activeSubItem = activeItem?.subItems?.find(
      subItem => location.pathname === subItem.href
    );

    const hiddenItemSubjects = NAV_ITEMS.filter(
      navItem =>
        navItem?.isHiddenSubject ||
        navItem.subItems?.some(subItem => subItem?.isHiddenSubject)
    ).map(navItem => ({
      ...navItem,
      subItems: navItem.subItems?.filter(subItem => subItem?.isHiddenSubject),
    }));

    const activeItemHiddenSubject = hiddenItemSubjects?.find(
      navItem =>
        location.pathname.includes(navItem?.href) ||
        navItem.subItems?.some(subItem =>
          location.pathname.includes(subItem.href)
        )
    );

    const activeSubItemHiddenSubject = hiddenItemSubjects
      .map(navItem => navItem.subItems)
      .flat()
      .find(subItem => location.pathname.includes(subItem.href));

    const title = activeItem?.title || activeItemHiddenSubject?.title || '';

    setActiveMenuTitle(title);
    setDocumentInfo({
      title,
      description:
        activeSubItem?.title || activeSubItemHiddenSubject?.title || '',
    });
  };

  useEffect(() => {
    handleActiveNavItem();
  }, []);

  useDocumentTitle(documentInfo);

  return (
    <S.Wrapper
      isMobile={isMobile}
      value={activeMenuTitle}
      onValueChange={title => setActiveMenuTitle(title)}
      type="single"
      collapsible
    >
      {mobileNavArea}

      {NAV_ITEMS.map(item =>
        item?.subItems?.length ? (
          <S.Item key={item.title} value={item.title}>
            <ItemTrigger
              title={item.title}
              isActiveItem={checkIfIsActiveItem(item)}
            >
              {getItemContent({
                item,
                isActive: checkIfIsActiveItem(item),
              })}
            </ItemTrigger>

            <ItemContent>
              {item?.subItems?.map(
                subItem =>
                  !subItem?.isHiddenSubject && (
                    <S.LinkItem
                      key={subItem.title}
                      target={subItem.isExternalLink && '_blank'}
                      rel={subItem.isExternalLink && 'noopener noreferrer'}
                      title={subItem.title}
                      href={subItem.href}
                      isSubItem
                      isActiveSubItem={location.pathname === subItem.href}
                    >
                      {getSubItemContent({
                        subItem,
                        isActive: location.pathname === subItem.href,
                      })}
                    </S.LinkItem>
                  )
              )}
            </ItemContent>
          </S.Item>
        ) : (
          <S.LinkItem
            key={item.title}
            target={item.isExternalLink && '_blank'}
            rel={item.isExternalLink && 'noopener noreferrer'}
            title={item.title}
            href={item.href}
            isActiveItem={checkIfIsActiveItem(item)}
          >
            {getItemContent({
              item,
              isActive: checkIfIsActiveItem(item),
            })}
          </S.LinkItem>
        )
      )}
    </S.Wrapper>
  );
};
