import { type IconProps } from '.';

export const OriginIcon = ({ color = '#8F9BB3', ...props }: IconProps) => {
  return (
    <svg
      className="origin-icon"
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.52499 4.66848L4.51166 1.09515C4.56769 1.02773 4.60989 0.949947 4.63586 0.866217C4.66183 0.782497 4.67105 0.694477 4.66301 0.607197C4.65496 0.519907 4.6298 0.435057 4.58896 0.357497C4.54812 0.279937 4.49241 0.211177 4.42499 0.155147C4.35758 0.0991174 4.27979 0.0569073 4.19607 0.0309373C4.11235 0.00496733 4.02433 -0.00425257 3.93704 0.00379743C3.84976 0.0118474 3.76491 0.0370074 3.68735 0.0778474C3.60978 0.118677 3.54102 0.174397 3.48499 0.241807L0.15166 4.24181C0.0536 4.3611 0 4.51073 0 4.66515C0 4.81956 0.0536 4.96919 0.15166 5.08848L3.37166 9.08846C3.43441 9.16586 3.51368 9.22816 3.60367 9.27086C3.69366 9.31346 3.79206 9.33546 3.89166 9.33516C4.01782 9.33556 4.14151 9.30016 4.24836 9.23316C4.35521 9.16606 4.44084 9.07006 4.49529 8.95626C4.54975 8.84246 4.5708 8.71556 4.55599 8.59026C4.54119 8.46496 4.49114 8.34646 4.41166 8.24846L1.52499 4.66848Z"
        fill={color}
      />
      <path
        d="M13.185 4.24843L9.99834 0.248435C9.88694 0.110515 9.72534 0.0225047 9.54904 0.00375472C9.37274 -0.0150053 9.19624 0.0370447 9.05834 0.148435C8.92044 0.259825 8.83244 0.421435 8.81364 0.597725C8.79494 0.774005 8.84694 0.950515 8.95834 1.08843L11.805 4.66843L8.81834 8.24846C8.76224 8.31576 8.71994 8.39356 8.69384 8.47736C8.66784 8.56106 8.65858 8.64905 8.66664 8.73645C8.67464 8.82375 8.69984 8.90856 8.74074 8.98616C8.78164 9.06376 8.83744 9.13246 8.90504 9.18846C9.02584 9.28546 9.17674 9.33726 9.33164 9.33506C9.42964 9.33526 9.52634 9.31386 9.61514 9.27236C9.70384 9.23086 9.78234 9.17036 9.84504 9.09506L13.1783 5.09509C13.2773 4.97658 13.3321 4.82738 13.3333 4.67296C13.3345 4.51855 13.2821 4.36849 13.185 4.24843Z"
        fill={color}
      />
      <path
        d="M9.14503 4.27514C9.02013 4.15097 8.85114 4.08128 8.67504 4.08128C8.49888 4.08128 8.32991 4.15097 8.205 4.27514L7.33167 5.09514V2.0018C7.33167 1.82499 7.26142 1.65542 7.1364 1.5304C7.01137 1.40538 6.84181 1.33514 6.665 1.33514C6.48819 1.33514 6.31862 1.40538 6.1936 1.5304C6.06857 1.65542 5.99833 1.82499 5.99833 2.0018V5.0618L5.13833 4.19514C5.0128 4.0696 4.84254 3.99908 4.665 3.99908C4.48747 3.99908 4.3172 4.0696 4.19167 4.19514C4.06613 4.32067 3.99561 4.49094 3.99561 4.66847C3.99561 4.846 4.06613 5.01627 4.19167 5.1418L6.19167 7.14185C6.25396 7.20355 6.32784 7.25245 6.40906 7.28565C6.49029 7.31885 6.57726 7.33565 6.665 7.33515C6.83665 7.33445 7.00142 7.26755 7.125 7.14845L9.12504 5.21514C9.25184 5.09289 9.32514 4.9254 9.32894 4.74926C9.33264 4.57313 9.26653 4.40267 9.14503 4.27514Z"
        fill={color}
      />
    </svg>
  );
};
