import { styled } from '../../styles/global';

export const Wrapper = styled('main', {
  display: 'flex',
  flexDirection: 'column',
  gap: '30px',

  padding: '90px 30px 90px 270px',

  '@small': {
    padding: '90px 0 100px',
    width: '90%',
    margin: '0 auto',
  },

  '@medium': {
    padding: '90px 0 100px',
    width: '90%',
    margin: '0 auto',
  },
});
