import { type ElementType, useState } from 'react';

import { type RadioGroupProps } from '../RadioGroup';

import * as S from './styles';

export type DetailedRadioRadioItem = {
  icon: ElementType;
  label: string;
  description: string;
  value: string;
};

export interface DetailedRadioGroup
  extends Omit<RadioGroupProps, 'columnDirection' | 'items'> {
  items: DetailedRadioRadioItem[];
}

export const DetailedRadioGroup = ({
  items,
  labelDirection = 'right',
  ...props
}: DetailedRadioGroup) => {
  const isComponentWithoutItems = !items.length;
  if (isComponentWithoutItems) {
    throw new Error(
      '(DetailedRadioGroup) You need to pass the `items` property for works correctly!'
    );
  }

  const [value, setValue] = useState<string | undefined>(
    props.defaultValue || props.value
  );

  const handleSelectedValue = (newValue: string) => {
    if (!props.disabled && newValue !== value) setValue(newValue);
  };

  return (
    <S.Wrapper
      className="detailed-radio-group-wrapper"
      {...props}
      value={value}
    >
      {items.map(({ icon: Icon, ...item }) => (
        <S.Item
          className="detailed-radio-item"
          key={item.value}
          id={item.label}
          value={item.value}
          checked={item.value === value}
          disabled={!!props.disabled}
          onClick={() => handleSelectedValue(item.value)}
        >
          {labelDirection === 'left' && (
            <S.Label htmlFor={item.label} disabled={!!props.disabled}>
              <Icon color="#0062DC" aria-hidden />

              <S.Column>
                {item.label}

                <S.LabelDescription disabled={!!props.disabled}>
                  {item.description}
                </S.LabelDescription>
              </S.Column>
            </S.Label>
          )}

          <S.Circle className="circle" disabled={!!props.disabled}>
            <S.Indicator
              checked={item.value === value}
              disabled={!!props.disabled}
            />
          </S.Circle>

          {labelDirection === 'right' && (
            <S.Label htmlFor={item.label} disabled={!!props.disabled}>
              <Icon color="#0062DC" aria-hidden />

              <S.Column>
                {item.label}

                <S.LabelDescription disabled={!!props.disabled}>
                  {item.description}
                </S.LabelDescription>
              </S.Column>
            </S.Label>
          )}
        </S.Item>
      ))}
    </S.Wrapper>
  );
};
