import { keyframes, styled } from '../../styles/global';

const spinner = keyframes({
  '0%': { transform: 'rotate(0deg)' },
  '100%': { transform: 'rotate(360deg)' },
});

export const Loading = styled('div', {
  alignItems: 'center',
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
});

export const Spinner = styled('div', {
  animation: `${spinner} 4s linear infinite`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: '5px solid #eaf0f6',
  borderRadius: '50%',
  borderTop: '5px solid #0062DC',
  height: '150px',
  margin: 'auto',
  position: 'relative',
  width: '150px',
});

export const EstocaLogo = styled('div', {
  animation: 'none',
  position: 'absolute',
  top: '50%',
  left: '35%',
  right: '50%',
  transform: 'translate(-50%, -50%)',
});

export const EstocaImg = styled('img', {
  width: '60px',
});
