import * as MyAccordion from '@radix-ui/react-accordion';

import { styled } from '../../styles/global';

import { slideDown, slideUp } from './animations';

export const Wrapper = styled(MyAccordion.Root, {
  display: 'flex',
  flexDirection: 'column',

  width: '100%',

  borderRadius: '$md',
  border: '1px solid #EDF1F7',

  backgroundColor: 'transparent',

  img: {
    userSelect: 'none',
  },
});

export const Item = styled(MyAccordion.Item, {
  width: 'inherit',

  display: 'flex',
  flexDirection: 'column',

  overflow: 'hidden',
  marginTop: '1px',

  '&:first-child': {
    marginTop: 0,
  },

  variants: {
    errorVariant: {
      true: {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '$unsuccess',

        borderRadius: '$md',
      },
    },
  },
});

export const Header = styled(MyAccordion.Header, {
  width: 'inherit',
  display: 'flex',

  variants: {
    errorVariant: {
      true: {
        '&[data-state="open"]': {
          borderBottomWidth: '1px',
          borderBottomStyle: 'solid',
          borderBottomColor: '$unsuccess',
        },
      },
    },
  },
});

export const Trigger = styled(MyAccordion.Trigger, {
  cursor: 'pointer',

  width: 'inherit',

  flex: 1,

  minHeight: '56px',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',

  gap: '16px',
  padding: '16px 24px',

  borderRadius: '$md',
  border: 'none',

  backgroundColor: '$white',

  color: '$black',
  fontWeight: '$semiBold',

  fontSize: '15px',
  lineHeight: '24px',

  '.alert-icon': {
    width: '20px',
    height: '20px',
  },

  '.arrow-icon': {
    width: '24px',
    height: '24px',
    transition: 'transform 300ms cubic-bezier(0.87, 0, 0.13, 1)',
  },

  '.label': {
    color: '$black',

    fontWeight: '$semiBold',
    fontSize: '15px',
    lineHeight: '24px',
  },

  '.quantity-indicator': {
    minWidth: '22px',
    height: '22px',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    padding: '8px',

    borderRadius: '$lg',

    backgroundColor: '$primary',
    color: '$white',

    fontFamily: '$base',
    fontStyle: '$normal',
    fontWeight: '$bold',
    fontSize: '10px',
    lineHeight: '15px',

    textAlign: 'center',
    textTransform: 'uppercase',
  },

  '.description': {
    color: '#495057',

    fontWeight: '$regular',
    fontSize: '12px',
    lineHeight: '18px',
  },

  '&[data-state="closed"] > .arrow-icon': {
    transform: 'rotate(0)',
  },

  '&[data-state="open"]': {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,

    '> .arrow-icon': {
      transform: 'rotate(180deg)',
    },
  },

  variants: {
    errorVariant: {
      true: {
        backgroundColor: '#FFF2F2',
      },
      false: {
        '&[data-state="open"]': {
          borderBottom: '0.8px solid #EDF1F7',
        },
      },
    },
  },
});

export const TriggerContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',

  marginRight: 'auto',
});

export const LabelWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});

export const ContentRoot = styled(MyAccordion.Content, {
  width: 'inherit',

  backgroundColor: '$white',

  borderRadius: '0 0 $md $md',

  overflow: 'hidden',

  '&[data-state="open"]': {
    animation: `${slideDown} .3s cubic-bezier(.25,.46,.45,.94) both`,
  },

  '&[data-state="closed"]': {
    animation: `${slideUp} .3s cubic-bezier(.25,.46,.45,.94) both`,
  },
});

export const ContentWrapper = styled('div', {
  width: 'inherit',

  maxHeight: '342px',

  display: 'flex',
  flexDirection: 'column',

  borderRadius: 'inherit',
  overflowY: 'auto',

  variants: {
    errorVariant: {
      false: {
        gap: '5px',
        padding: '20px',

        border: '1px solid #EDF1F7',
      },
    },
    contentWithoutSpacing: {
      true: {
        gap: 'normal',
        padding: '0',
      },
    },
  },
});
