import { type IconProps } from '.';

export const StatusIcon = ({ color = '#8F9BB3', ...props }: IconProps) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.6666 0.666687C10.4014 0.666687 10.147 0.772035 9.95951 0.959571C9.77197 1.14711 9.66663 1.40147 9.66663 1.66668V3.66669C9.66663 3.9319 9.77197 4.18625 9.95951 4.37379C10.147 4.56132 10.4014 4.66668 10.6666 4.66668C10.9319 4.66668 11.1861 4.56132 11.3737 4.37379C11.5613 4.18625 11.6666 3.9319 11.6666 3.66669V1.66668C11.6666 1.40147 11.5613 1.14711 11.3737 0.959571C11.1861 0.772035 10.9319 0.666687 10.6666 0.666687Z"
        fill={color}
      />
      <path
        d="M19.6667 9.66669H17.6666C17.4014 9.66669 17.1471 9.77204 16.9596 9.95957C16.772 10.1471 16.6667 10.4015 16.6667 10.6667C16.6667 10.9319 16.772 11.1863 16.9596 11.3738C17.1471 11.5614 17.4014 11.6667 17.6666 11.6667H19.6667C19.9319 11.6667 20.1863 11.5614 20.3738 11.3738C20.5613 11.1863 20.6666 10.9319 20.6666 10.6667C20.6666 10.4015 20.5613 10.1471 20.3738 9.95957C20.1863 9.77204 19.9319 9.66669 19.6667 9.66669Z"
        fill={color}
      />
      <path
        d="M4.66662 10.6667C4.66662 10.4015 4.56126 10.1471 4.37372 9.95957C4.18619 9.77204 3.93184 9.66669 3.66662 9.66669H1.66662C1.40141 9.66669 1.14705 9.77204 0.95951 9.95957C0.771974 10.1471 0.666626 10.4015 0.666626 10.6667C0.666626 10.9319 0.771974 11.1863 0.95951 11.3738C1.14705 11.5614 1.40141 11.6667 1.66662 11.6667H3.66662C3.93184 11.6667 4.18619 11.5614 4.37372 11.3738C4.56126 11.1863 4.66662 10.9319 4.66662 10.6667Z"
        fill={color}
      />
      <path
        d="M4.88673 3.66667C4.6918 3.48235 4.43163 3.38302 4.16345 3.39052C3.89527 3.39802 3.64106 3.51174 3.45674 3.70668C3.27241 3.90161 3.17307 4.16178 3.18057 4.42996C3.18807 4.69813 3.3018 4.95235 3.49674 5.13667L4.93673 6.52668C5.03337 6.61999 5.14781 6.69287 5.27324 6.74098C5.39866 6.78908 5.53249 6.81143 5.66673 6.80668C5.80144 6.80616 5.93465 6.77844 6.05837 6.72517C6.18211 6.67189 6.29379 6.59418 6.38673 6.49668C6.57298 6.30931 6.67753 6.05586 6.67753 5.79168C6.67753 5.52749 6.57298 5.27404 6.38673 5.08668L4.88673 3.66667Z"
        fill={color}
      />
      <path
        d="M15.6667 6.80663C15.9242 6.8056 16.1713 6.70531 16.3567 6.52663L17.7967 5.13663C17.9724 4.95308 18.0716 4.70955 18.0743 4.4555C18.0768 4.20145 17.9827 3.95593 17.8109 3.7688C17.6389 3.58167 17.4023 3.46697 17.1489 3.448C16.8956 3.42902 16.6446 3.50719 16.4467 3.66663L15.0067 5.08663C14.8205 5.27399 14.7159 5.52745 14.7159 5.79163C14.7159 6.05582 14.8205 6.30927 15.0067 6.49663C15.1799 6.67935 15.4155 6.79005 15.6667 6.80663Z"
        fill={color}
      />
      <path
        d="M10.6666 16.6667C10.4014 16.6667 10.147 16.772 9.95951 16.9596C9.77197 17.1471 9.66663 17.4014 9.66663 17.6666V19.6667C9.66663 19.9319 9.77197 20.1863 9.95951 20.3738C10.147 20.5613 10.4014 20.6666 10.6666 20.6666C10.9319 20.6666 11.1861 20.5613 11.3737 20.3738C11.5613 20.1863 11.6666 19.9319 11.6666 19.6667V17.6666C11.6666 17.4014 11.5613 17.1471 11.3737 16.9596C11.1861 16.772 10.9319 16.6667 10.6666 16.6667Z"
        fill={color}
      />
      <path
        d="M16.3967 14.8067C16.2057 14.6223 15.9494 14.5214 15.684 14.5261C15.4187 14.5308 15.1661 14.6407 14.9817 14.8316C14.7974 15.0227 14.6965 15.279 14.7012 15.5443C14.7059 15.8097 14.8158 16.0623 15.0067 16.2467L16.4467 17.6666C16.6321 17.8453 16.8792 17.9456 17.1367 17.9467C17.2707 17.9474 17.4035 17.9213 17.5272 17.8698C17.6508 17.8182 17.7629 17.7423 17.8567 17.6467C17.9504 17.5537 18.0248 17.4431 18.0756 17.3213C18.1263 17.1993 18.1525 17.0687 18.1525 16.9367C18.1525 16.8047 18.1263 16.674 18.0756 16.5521C18.0248 16.4303 17.9504 16.3196 17.8567 16.2266L16.3967 14.8067Z"
        fill={color}
      />
      <path
        d="M4.9366 14.8067L3.49661 16.1966C3.40287 16.2896 3.32849 16.4003 3.27771 16.5221C3.22694 16.644 3.20081 16.7747 3.20081 16.9067C3.20081 17.0387 3.22694 17.1693 3.27771 17.2913C3.32849 17.4131 3.40287 17.5237 3.49661 17.6167C3.5904 17.7123 3.70247 17.7882 3.82615 17.8398C3.94983 17.8913 4.08261 17.9174 4.21661 17.9167C4.46312 17.9187 4.70172 17.8297 4.8866 17.6666L6.3266 16.2767C6.51756 16.0923 6.62748 15.8397 6.63216 15.5743C6.63685 15.309 6.53593 15.0527 6.35161 14.8616C6.16728 14.6707 5.91465 14.5608 5.64928 14.5561C5.38392 14.5514 5.12756 14.6523 4.9366 14.8367V14.8067Z"
        fill={color}
      />
    </svg>
  );
};
