import * as RadioGroup from '@radix-ui/react-radio-group';

import { styled } from '../../styles/global';

import { scaleUpCenter, scaleDownCenter } from './animations';

export const Wrapper = styled(RadioGroup.Root, {
  display: 'flex',
  gap: '16px',

  width: 'fit-content',

  variants: {
    isColumnDirection: {
      true: {
        flexDirection: 'column',
      },
    },
  },
});

export const Item = styled('div', {
  display: 'flex',
  alignItems: 'center',

  gap: '4px',

  variants: {
    disabled: {
      true: {
        cursor: 'not-allowed',
      },
    },
  },
});

export const Circle = styled(RadioGroup.Item, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  width: '20px',
  height: '20px',

  backgroundColor: '$white',
  borderRadius: '$circular',

  borderWidth: '2px',
  borderStyle: 'solid',

  '&[data-state="checked"]': {
    borderColor: '$primary',
  },

  '&[data-state="unchecked"]': {
    borderColor: '$gray',
  },

  variants: {
    disabled: {
      true: {
        borderColor: '$gray',
        pointerEvents: 'none',
      },
    },
  },
});

export const Indicator = styled(RadioGroup.Indicator, {
  width: '8px',
  height: '8px',

  margin: 'auto',

  borderRadius: '$circular',

  '&[data-state="checked"]': {
    animation: `${scaleUpCenter} .15s cubic-bezier(.55,.085,.68,.53) both`,
  },

  '&[data-state="unchecked"]': {
    animation: `${scaleDownCenter} .15s cubic-bezier(.25,.46,.45,.94) both`,
  },

  variants: {
    checked: {
      true: {
        backgroundColor: '$primary',
      },
    },
    disabled: {
      true: {
        backgroundColor: '$gray',
      },
    },
  },
});

export const Label = styled('label', {
  userSelect: 'none',

  color: '$black',

  fontFamily: '$base',
  fontStyle: '$normal',
  fontWeight: '$regular',
  fontSize: '12px',
  lineHeight: '18px',

  variants: {
    disabled: {
      true: {
        color: '$gray',
        pointerEvents: 'none',
      },
    },
  },
});
