import { type IconProps } from '.';

export const LocationIcon = ({ color = '#8F9BB3', ...props }: IconProps) => {
  return (
    <svg
      className="location-icon"
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.00008 0.333374C4.59479 0.333304 3.24625 0.887884 2.24758 1.87657C1.2489 2.86526 0.680798 4.20815 0.666748 5.61337C0.666748 9.2667 5.36675 13.3334 5.56675 13.5067C5.6875 13.61 5.84118 13.6668 6.00008 13.6668C6.15898 13.6668 6.31266 13.61 6.43341 13.5067C6.66675 13.3334 11.3334 9.2667 11.3334 5.61337C11.3194 4.20815 10.7513 2.86526 9.7526 1.87657C8.7539 0.887884 7.40538 0.333304 6.00008 0.333374ZM6.00008 12.1C4.88675 11.04 2.00008 8.10004 2.00008 5.61337C2.00008 4.55251 2.42151 3.53509 3.17165 2.78495C3.9218 2.0348 4.93922 1.61337 6.00008 1.61337C7.06095 1.61337 8.0784 2.0348 8.8285 2.78495C9.5787 3.53509 10.0001 4.55251 10.0001 5.61337C10.0001 8.08004 7.11341 11.04 6.00008 12.1Z"
        fill={color}
      />
      <path
        d="M6.00008 3C5.53859 3 5.08747 3.13685 4.70375 3.39324C4.32004 3.64963 4.02097 4.01404 3.84436 4.44041C3.66776 4.86677 3.62155 5.33592 3.71158 5.78854C3.80162 6.24117 4.02384 6.65693 4.35017 6.98325C4.67649 7.30957 5.09225 7.5318 5.54487 7.62183C5.99749 7.71186 6.46665 7.66566 6.89301 7.48905C7.31937 7.31245 7.68379 7.01338 7.94018 6.62966C8.1966 6.24595 8.3334 5.79482 8.3334 5.33333C8.3334 4.71449 8.0876 4.121 7.65 3.68342C7.21241 3.24583 6.61892 3 6.00008 3ZM6.00008 6.33333C5.8023 6.33333 5.60896 6.27468 5.44451 6.1648C5.28006 6.05492 5.15189 5.89874 5.0762 5.71602C5.00052 5.53329 4.98071 5.33222 5.0193 5.13824C5.05788 4.94426 5.15312 4.76608 5.29298 4.62623C5.43283 4.48637 5.61101 4.39113 5.80499 4.35255C5.99897 4.31396 6.20004 4.33377 6.38277 4.40945C6.56549 4.48514 6.72167 4.61331 6.83155 4.77776C6.94143 4.94221 7.00008 5.13555 7.00008 5.33333C7.00008 5.59855 6.89473 5.8529 6.70719 6.04044C6.51965 6.22798 6.2653 6.33333 6.00008 6.33333Z"
        fill={color}
      />
    </svg>
  );
};
