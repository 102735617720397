import { type IconProps } from '.';

export const ClosedEyeIcon = ({ color = '#8F9BB3', ...props }: IconProps) => {
  return (
    <svg
      className="closed-eye-icon"
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.80948 0.19607C1.74732 0.13391 1.67353 0.0845999 1.59232 0.0509599C1.5111 0.0173199 1.42406 0 1.33615 0C1.24824 0 1.1612 0.0173199 1.07998 0.0509599C0.998768 0.0845999 0.924968 0.13391 0.862818 0.19607C0.737278 0.3216 0.666748 0.49186 0.666748 0.6694C0.666748 0.84693 0.737278 1.0172 0.862818 1.14273L4.61615 4.89607C4.37869 5.33781 4.28987 5.84431 4.36287 6.3405C4.43588 6.83668 4.66681 7.29614 5.02144 7.65077C5.37607 8.00542 5.83553 8.23632 6.33172 8.30932C6.8279 8.38232 7.3344 8.29352 7.77615 8.05602L11.5294 11.8094C11.5914 11.8719 11.6652 11.9215 11.7464 11.9553C11.8276 11.9892 11.9148 12.0066 12.0028 12.0066C12.0908 12.0066 12.1779 11.9892 12.2592 11.9553C12.3404 11.9215 12.4141 11.8719 12.4761 11.8094C12.5386 11.7474 12.5882 11.6737 12.622 11.5924C12.6559 11.5112 12.6733 11.4241 12.6733 11.336C12.6733 11.248 12.6559 11.1609 12.622 11.0797C12.5882 10.9984 12.5386 10.9247 12.4761 10.8627L1.80948 0.19607ZM6.66948 7.00273C6.40427 7.00273 6.14991 6.89738 5.96238 6.70984C5.77484 6.5223 5.66948 6.26795 5.66948 6.00273V5.95607L6.70948 6.99606L6.66948 7.00273Z"
        fill={color}
      />
      <path
        d="M6.81598 9.33602C3.94931 9.40272 2.06931 6.94273 1.48265 6.00273C1.90029 5.33652 2.40222 4.72704 2.97598 4.18939L2.00265 3.24939C1.2502 3.95837 0.60559 4.77368 0.0893101 5.66939C0.0308 5.77074 0 5.8857 0 6.00273C0 6.11975 0.0308 6.23471 0.0893101 6.33606C0.50931 7.06273 2.75598 10.6694 6.68265 10.6694H6.84931C7.58768 10.6475 8.31649 10.4963 9.00263 10.2227L7.94931 9.16942C7.57889 9.26682 7.19877 9.32272 6.81598 9.33602Z"
        fill={color}
      />
      <path
        d="M13.2495 5.66936C12.8228 4.92936 10.4695 1.21603 6.48951 1.33603C5.75115 1.35793 5.02234 1.50912 4.33618 1.78271L5.38951 2.83604C5.75993 2.73859 6.14005 2.68268 6.52284 2.66936C9.38283 2.59603 11.2628 5.06271 11.8561 6.00271C11.4282 6.6709 10.915 7.28048 10.3295 7.81604L11.3361 8.75601C12.098 8.04891 12.7517 7.23351 13.2761 6.33603C13.3307 6.23236 13.3569 6.11616 13.3522 5.99913C13.3475 5.88209 13.3121 5.76835 13.2495 5.66936Z"
        fill={color}
      />
    </svg>
  );
};
