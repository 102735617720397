import {
  useState,
  type ComponentProps,
  type ElementType,
  type ReactNode,
} from 'react';

import { AlertIcon } from '../Icons';

import { AccordionContent } from './AccordionContent';
import { AccordionTrigger } from './AccordionTrigger';
import * as S from './styles';

type Icon = {
  element: ElementType;
  size: number | string;
};

export type AccordionProps = ComponentProps<typeof S.Wrapper> & {
  children: ReactNode;
  label: string;
  icon?: Icon;
  quantityIndicator?: number;
  description?: string;
  openedByDefault?: boolean;
  errorVariant?: boolean;
  contentWithoutSpacing?: boolean;
};

export const Accordion = ({
  children,
  label,
  icon,
  quantityIndicator,
  description,
  openedByDefault = false,
  errorVariant = false,
  contentWithoutSpacing = false,
  ...props
}: AccordionProps) => {
  const [myValues, setMyValues] = useState<string | string[]>(() => {
    if (openedByDefault) {
      return props.type === 'multiple' ? [label] : label;
    }

    return props.type === 'multiple' ? [] : '';
  });

  props.value = myValues;

  props.onValueChange = (updatedValue: string | string[]) =>
    setMyValues(updatedValue);

  const { element: Icon } = icon || {};

  const triggerContentCSS = icon
    ? {
        '.label-icon': {
          width: icon.size,
          height: icon.size,
        },
      }
    : undefined;

  return (
    <S.Wrapper {...props}>
      <S.Item value={label} errorVariant={errorVariant}>
        <AccordionTrigger value={props.value} errorVariant={errorVariant}>
          {errorVariant && <AlertIcon className="alert-icon" color="#DD3535" />}

          <S.TriggerContent css={triggerContentCSS}>
            {Icon || quantityIndicator ? (
              <>
                <S.LabelWrapper>
                  {Icon && (
                    <Icon className="label-icon" color="#181921" aria-hidden />
                  )}
                  <span className="label">{label}</span>
                  {quantityIndicator && (
                    <span className="quantity-indicator">
                      {quantityIndicator}
                    </span>
                  )}
                </S.LabelWrapper>

                {!!description && (
                  <span className="description">{description}</span>
                )}
              </>
            ) : (
              <>
                <span className="label">{label}</span>
                {!!description && (
                  <span className="description">{description}</span>
                )}
              </>
            )}
          </S.TriggerContent>
        </AccordionTrigger>

        <AccordionContent
          errorVariant={errorVariant}
          contentWithoutSpacing={contentWithoutSpacing}
        >
          {children}
        </AccordionContent>
      </S.Item>
    </S.Wrapper>
  );
};
