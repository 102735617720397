import { ComponentProps } from '@stitches/react';
import {
  type MutableRefObject,
  Ref,
  forwardRef,
  useEffect,
  useState,
} from 'react';

import * as S from './Progress/styles';

export type ProgressStepValue = {
  label: string;
  /**
   * Integer value like percentage
   * ex: 10 equals 10%
   */
  progressValue: number;
};

export interface ProgressProps
  extends Omit<ComponentProps<typeof S.Root>, 'value' | 'max'> {
  values: ProgressStepValue[];
  activeStep: number;
}

export const ProgressStep = forwardRef(
  (
    { values, activeStep, ...props }: ProgressProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const maxValue = values.at(-1)?.progressValue as number;

    const [progressValue, setProgressValue] = useState<number>(
      values[0]?.progressValue
    );

    useEffect(() => {
      setProgressValue(value => values[activeStep - 1]?.progressValue || value);
    }, [activeStep, values]);

    return (
      <S.Root ref={ref} {...props} value={progressValue} max={maxValue}>
        <S.Indicator
          css={{ transform: `translateX(-${maxValue - progressValue}%)` }}
        />
      </S.Root>
    );
  }
);

interface UseProgressWidthProps {
  progressRef: MutableRefObject<HTMLDivElement | null>;
  isLoading: boolean;
}

export const useProgressWidth = ({
  progressRef,
  isLoading,
}: UseProgressWidthProps) => {
  const [progressWidth, setProgressWidth] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    if (!isLoading && progressRef?.current) {
      setProgressWidth(`${progressRef.current.offsetWidth}px`);
    }
  }, [progressRef, isLoading]);

  return { progressWidth };
};
