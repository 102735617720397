import { forwardRef, type ComponentProps, type Ref } from 'react';

import * as S from './styles';

interface AccordionContentProps extends ComponentProps<typeof S.ContentRoot> {
  errorVariant?: boolean;
  contentWithoutSpacing?: boolean;
}

export const AccordionContent = forwardRef(
  (
    {
      children,
      errorVariant = false,
      contentWithoutSpacing = false,
      ...props
    }: AccordionContentProps,
    ref: Ref<HTMLDivElement>
  ) => (
    <S.ContentRoot {...props} ref={ref}>
      <S.ContentWrapper
        errorVariant={errorVariant}
        contentWithoutSpacing={contentWithoutSpacing}
      >
        {children}
      </S.ContentWrapper>
    </S.ContentRoot>
  )
);
