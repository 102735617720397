import { styled } from '../../styles/global';

export const Wrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',

  gap: '20px',
  padding: '20px',

  background: '$white',
  border: '1px solid #E5EBF5',

  borderRadius: '$md',
});

export const IconWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  minWidth: '60px',
  minHeight: '60px',

  background: 'linear-gradient(99.26deg, #0062DC 1.09%, #3f6ad8 100.13%)',
  boxShadow:
    '0px 7.5px 35px rgba(4, 9, 20, 0.03), 0px 15px 22.5px rgba(4, 9, 20, 0.03), 0px 4px 8.5px rgba(4, 9, 20, 0.05), 0px 2px 3px rgba(4, 9, 20, 0.03)',

  borderRadius: '$sm',

  'img, svg': {
    width: '24px',
    height: '24px',
  },
});

export const InfoWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',

  gap: '4px',

  '.title': {
    color: '$black',

    fontFamily: '$base',
    fontStyle: '$normal',
    fontWeight: '$semiBold',
    fontSize: '20px',
    lineHeight: '26px',
  },

  '.description': {
    color: '#495057',

    fontFamily: '$base',
    fontStyle: '$normal',
    fontWeight: '$regular',
    fontSize: '14px',
    lineHeight: '20px',
  },
});
