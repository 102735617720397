import { type IconProps } from '.';

export const ArrowRightIcon = ({ color = '#8F9BB3', ...props }: IconProps) => {
  return (
    <svg
      className="arrow-right-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="7"
      height="10"
      viewBox="0 0 7 10"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.50001 9.99999C1.24401 9.99999 0.988006 9.90199 0.793006 9.70699C0.402006 9.31599 0.402006 8.68399 0.793006 8.29299L4.09801 4.98799L0.918006 1.69499C0.535006 1.29699 0.546006 0.663989 0.943006 0.280989C1.34101 -0.10201 1.97401 -0.0910103 2.35701 0.304989L6.21901 4.30499C6.59801 4.69799 6.59301 5.32099 6.20701 5.70699L2.20701 9.70699C2.01201 9.90199 1.75601 9.99999 1.50001 9.99999Z"
        fill={color}
      />
    </svg>
  );
};
