import { type ElementRef, useRef } from 'react';

import {
  ProgressStep,
  useProgressWidth,
  type ProgressStepValue,
} from '../ProgressStep';
import { ShimmerLoading } from '../ShimmerLoading';

import { StatusCheck, rightAndLeftSeparatorWidth } from './StatusCheck';
import * as S from './styles';

const Loading = () => <ShimmerLoading as={S.Wrapper} />;

export type OrderStepProgressStepsValue = ProgressStepValue & {
  relatedStatuses: string[];
  description: string;
  isUncompleted?: boolean;
  onStepClick: () => void;
};

type ActiveStatus = {
  status: string;
  index: number;
};

interface OrderStepStatusProps {
  isLoading: boolean;
  activeStatus: ActiveStatus;
  stepIndex: number;
  progressStepValues: OrderStepProgressStepsValue[];
}

export const OrderStepStatus = ({
  isLoading,
  activeStatus,
  stepIndex,
  progressStepValues,
}: OrderStepStatusProps) => {
  const progressRef = useRef<ElementRef<'div'>>(null);
  const { progressWidth } = useProgressWidth({ progressRef, isLoading });

  if (isLoading || !progressStepValues.length) return <Loading />;

  return (
    <S.Wrapper
      className="progress-step-wrapper"
      css={{
        width: `calc(${rightAndLeftSeparatorWidth} * ${progressStepValues.length})`,
      }}
    >
      <ProgressStep
        className="progress-step"
        ref={progressRef}
        activeStep={activeStatus.index}
        values={progressStepValues}
      />

      {progressStepValues.map((step, index) => (
        <StatusCheck
          key={step.label}
          progressWidth={progressWidth}
          index={index + 1}
          isActiveStepChecked={index + 1 <= activeStatus.index}
          isUncompletedStep={!!step?.isUncompleted}
          hasActionClick={index + 1 <= stepIndex}
          activeStatus={activeStatus.status}
          {...step}
        />
      ))}
    </S.Wrapper>
  );
};
