import { type IconProps } from '.';

export const UploadIcon = ({ color = '#8F9BB3', ...props }: IconProps) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.16683 3.33331H15.8335C16.2918 3.33331 16.6668 3.70831 16.6668 4.16665L16.6668 5.83331C16.6668 6.29165 16.2918 6.66665 15.8335 6.66665C15.3752 6.66665 15.0002 6.29165 15.0002 5.83331V4.99998H5.00016V5.83331C5.00016 6.29165 4.62516 6.66665 4.16683 6.66665C3.7085 6.66665 3.3335 6.29165 3.3335 5.83331V4.16665C3.3335 3.70831 3.7085 3.33331 4.16683 3.33331ZM6.66766 11.6666C6.4135 11.6666 6.16433 11.5516 6.00016 11.3333C5.7235 10.965 5.7985 10.4425 6.16683 10.1666L9.50016 7.66665C9.7885 7.44998 10.1852 7.44415 10.4793 7.65165L13.8127 9.99665C14.1893 10.2616 14.2802 10.7816 14.0152 11.1575C13.7502 11.5333 13.231 11.625 12.8543 11.36L10.8311 9.93673C10.8327 9.95761 10.8335 9.9787 10.8335 9.99998V16.6666C10.8335 17.1266 10.461 17.5 10.0002 17.5C9.53933 17.5 9.16683 17.1266 9.16683 16.6666V10.0001L7.16683 11.5C7.01683 11.6125 6.84183 11.6666 6.66766 11.6666Z"
        fill={color}
      />
      <mask
        id="mask0_371_13043"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="3"
        y="3"
        width="14"
        height="15"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.16683 3.33331H15.8335C16.2918 3.33331 16.6668 3.70831 16.6668 4.16665L16.6668 5.83331C16.6668 6.29165 16.2918 6.66665 15.8335 6.66665C15.3752 6.66665 15.0002 6.29165 15.0002 5.83331V4.99998H5.00016V5.83331C5.00016 6.29165 4.62516 6.66665 4.16683 6.66665C3.7085 6.66665 3.3335 6.29165 3.3335 5.83331V4.16665C3.3335 3.70831 3.7085 3.33331 4.16683 3.33331ZM6.66766 11.6666C6.4135 11.6666 6.16433 11.5516 6.00016 11.3333C5.7235 10.965 5.7985 10.4425 6.16683 10.1666L9.50016 7.66665C9.7885 7.44998 10.1852 7.44415 10.4793 7.65165L13.8127 9.99665C14.1893 10.2616 14.2802 10.7816 14.0152 11.1575C13.7502 11.5333 13.231 11.625 12.8543 11.36L10.8311 9.93673C10.8327 9.95761 10.8335 9.9787 10.8335 9.99998V16.6666C10.8335 17.1266 10.461 17.5 10.0002 17.5C9.53933 17.5 9.16683 17.1266 9.16683 16.6666V10.0001L7.16683 11.5C7.01683 11.6125 6.84183 11.6666 6.66766 11.6666Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_371_13043)">
        <rect width="20" height="20" fill={color} />
      </g>
    </svg>
  );
};
