import { type IconProps } from '.';

export const FindIcon = ({ color = '#8F9BB3', ...props }: IconProps) => {
  return (
    <svg
      className="find-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.22221 8.88876C2.22221 5.21215 5.21216 2.22219 8.88878 2.22219C12.5654 2.22219 15.5554 5.21215 15.5554 8.88876C15.5554 12.5653 12.5654 15.5553 8.88878 15.5553C5.21216 15.5553 2.22221 12.5653 2.22221 8.88876ZM19.6742 18.1031L15.902 14.3298C17.072 12.8253 17.7775 10.9398 17.7775 8.88876C17.7775 3.98772 13.7898 0 8.88876 0C3.98772 0 0 3.98772 0 8.88876C0 13.7898 3.98772 17.7775 8.88876 17.7775C10.9398 17.7775 12.8253 17.072 14.3298 15.902L18.1031 19.6742C18.3197 19.8908 18.6042 19.9997 18.8886 19.9997C19.1731 19.9997 19.4575 19.8908 19.6742 19.6742C20.1086 19.2397 20.1086 18.5375 19.6742 18.1031Z"
        fill={color}
      />
    </svg>
  );
};
