import { type IconProps } from '.';

export const PersonIcon = ({ color = '#8F9BB3', ...props }: IconProps) => {
  return (
    <svg
      className="person-icon"
      width="10"
      height="12"
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.00016 5.33333C5.52758 5.33333 6.04315 5.17694 6.48168 4.88392C6.92021 4.5909 7.262 4.17443 7.4638 3.68716C7.6657 3.19989 7.7185 2.66371 7.6156 2.14643C7.5127 1.62914 7.2587 1.15399 6.88578 0.781048C6.51284 0.408108 6.03769 0.154128 5.5204 0.0512375C5.00312 -0.0516525 4.46694 0.00115761 3.97967 0.202988C3.4924 0.404818 3.07593 0.746617 2.78291 1.18515C2.48989 1.62368 2.3335 2.13925 2.3335 2.66667C2.3335 3.37391 2.61445 4.05219 3.11454 4.55229C3.61464 5.05238 4.29292 5.33333 5.00016 5.33333ZM5.00016 1.33333C5.26387 1.33333 5.52166 1.41153 5.74092 1.55804C5.96019 1.70455 6.13109 1.91279 6.232 2.15642C6.33292 2.40006 6.35932 2.66815 6.30788 2.92679C6.25643 3.18543 6.12944 3.42301 5.94297 3.60948C5.7565 3.79595 5.51892 3.92294 5.26028 3.97438C5.00164 4.02583 4.73355 3.99942 4.48992 3.89851C4.24628 3.79759 4.03805 3.62669 3.89154 3.40743C3.74503 3.18816 3.66683 2.93038 3.66683 2.66667C3.66683 2.31305 3.80731 1.97391 4.05735 1.72386C4.3074 1.47381 4.64654 1.33333 5.00016 1.33333Z"
        fill={color}
      />
      <path
        d="M5.00016 6.66663C3.76249 6.66663 2.5755 7.15829 1.70033 8.0335C0.825156 8.9086 0.333496 10.0956 0.333496 11.3333C0.333496 11.5101 0.403726 11.6797 0.528756 11.8047C0.653776 11.9297 0.823346 12 1.00016 12C1.17697 12 1.34654 11.9297 1.47157 11.8047C1.59659 11.6797 1.66683 11.5101 1.66683 11.3333C1.66683 10.4492 2.01802 9.6014 2.64314 8.9763C3.26826 8.3511 4.11611 7.99996 5.00016 7.99996C5.88422 7.99996 6.73206 8.3511 7.3572 8.9763C7.9823 9.6014 8.3335 10.4492 8.3335 11.3333C8.3335 11.5101 8.4037 11.6797 8.5288 11.8047C8.6538 11.9297 8.8234 12 9.0002 12C9.177 12 9.3465 11.9297 9.4716 11.8047C9.5966 11.6797 9.6668 11.5101 9.6668 11.3333C9.6668 10.0956 9.1752 8.9086 8.3 8.0335C7.4248 7.15829 6.23784 6.66663 5.00016 6.66663Z"
        fill={color}
      />
    </svg>
  );
};
