import * as RadioGroup from '@radix-ui/react-radio-group';

import { styled } from '../../styles/global';
import { scaleUpCenter, scaleDownCenter } from '../RadioGroup/animations';

export const Wrapper = styled(RadioGroup.Root, {
  display: 'flex',
  gap: '16px',

  width: 'fit-content',

  variants: {
    isColumnDirection: {
      true: {
        flexDirection: 'column',
      },
    },
  },
});

export const Item = styled(RadioGroup.Item, {
  cursor: 'default',

  display: 'flex',
  alignItems: 'center',

  padding: '16px',
  gap: '16px',

  borderRadius: '$md',

  '&[data-state="checked"]': {
    border: '1px solid #0062DC',
    backgroundColor: 'rgba(229, 235, 245, 0.5)',

    '.circle': {
      borderColor: '$primary',
    },
  },

  '&[data-state="unchecked"]': {
    border: '1px solid #E5EBF5',

    '.circle': {
      borderColor: '$gray',
    },
  },

  variants: {
    disabled: {
      true: {
        cursor: 'not-allowed',
      },
    },
  },
});

export const Circle = styled('span', {
  cursor: 'pointer',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  width: '20px',
  height: '20px',

  backgroundColor: '$white',
  borderRadius: '$circular',

  borderWidth: '2px',
  borderStyle: 'solid',
  borderColor: '$primary',

  variants: {
    disabled: {
      true: {
        borderColor: '$gray',
        pointerEvents: 'none',
      },
    },
  },
});

export const Indicator = styled(RadioGroup.Indicator, {
  width: '8px',
  height: '8px',

  margin: 'auto',

  borderRadius: '$circular',

  // display: 'flex',
  // alignItems: 'center',
  // justifyContent: 'center',

  // width: '100%',
  // height: '100%',

  // position: 'relative',

  // '&::after': {
  //   content: '""',
  //   display: 'block',

  //   width: '8px',
  //   height: '8px',

  //   borderRadius: '$circular',

  //   backgroundColor: '$primary',
  // },

  '&[data-state="checked"]': {
    animation: `${scaleUpCenter} .15s cubic-bezier(.55,.085,.68,.53) both`,
  },

  '&[data-state="unchecked"]': {
    animation: `${scaleDownCenter} .15s cubic-bezier(.25,.46,.45,.94) both`,
  },

  variants: {
    checked: {
      true: {
        backgroundColor: '$primary',

        // '&::after': {
        //   backgroundColor: '$primary',
        // },
      },
    },
    disabled: {
      true: {
        backgroundColor: '$gray',

        // '&::after': {
        //   backgroundColor: '$gray',
        // },
      },
    },
  },
});

export const Label = styled('label', {
  display: 'flex',
  flexDirection: 'column',

  gap: '8px',

  userSelect: 'none',

  color: '$black',

  fontFamily: '$base',
  fontStyle: '$normal',
  fontWeight: '$semiBold',
  fontSize: '12px',
  lineHeight: '18px',

  '[class*="icon"]': {
    width: '18px',
    height: '18px',
  },

  variants: {
    disabled: {
      true: {
        color: '$gray',
        pointerEvents: 'none',
      },
    },
  },
});

export const Column = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
});

export const LabelDescription = styled('p', {
  color: '$darkGrey',

  fontFamily: '$base',
  fontStyle: '$normal',
  fontWeight: '$regular',
  fontSize: '10px',
  lineHeight: '15px',

  variants: {
    disabled: {
      true: {
        color: '$gray',
      },
    },
  },
});
