import { EstocaLoading, PageContentWrapper } from '../../components';

import * as S from './styles';

export const PageLoading = () => {
  return (
    <PageContentWrapper>
      <S.ContainerLoading>
        <EstocaLoading />
        <S.TitleLoading>
          Aguarde um instante, estamos carregando a aplicação para você.
        </S.TitleLoading>
      </S.ContainerLoading>
    </PageContentWrapper>
  );
};
