import { type CSSProperties } from '@stitches/react';

import { Loading } from './styles';

interface SpinnerLoadingProps {
  size?: CSSProperties['width'];
  color?: CSSProperties['color'];
}

export const SpinnerLoading = ({
  size = '48px',
  color = '#0062DC',
}: SpinnerLoadingProps) => {
  return <Loading css={{ width: size, height: size, borderTopColor: color }} />;
};
