import { PageContentWrapper } from '../../components';

import * as S from './styles';

export const NotFound = () => {
  return (
    <PageContentWrapper>
      <S.ContainerNotFound>
        <S.Title404> 404 </S.Title404>
        <S.Subtitle> Página não encontrada !</S.Subtitle>
        <S.ContainerDescription>
          <S.Description>
            Desculpe, a página solicitada não foi encontrada. Clique no botão
            abaixo para voltar ao início.
          </S.Description>
        </S.ContainerDescription>
        <S.Button onClick={() => (window.location.href = '/dashboard')}>
          ACESSAR A DASHBOARD
        </S.Button>
        <S.Help>
          Precisa de ajuda ? &nbsp;
          <S.ContactSupport
            onClick={() =>
              (window.location.href = 'https://estoca.zendesk.com/agent/')
            }
          >
            Contatar suporte.
          </S.ContactSupport>
        </S.Help>
      </S.ContainerNotFound>
    </PageContentWrapper>
  );
};
