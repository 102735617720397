import { PageContentWrapper } from '../../components';

import * as S from './styles';

export const CriticalError = () => {
  return (
    <PageContentWrapper>
      <S.ContainerCritical>
        <S.Title500> 500 </S.Title500>
        <S.Subtitle> Erro ao carregar a página !</S.Subtitle>
        <S.ContainerDescription>
          <S.Description>
            Desculpe, algo deu errado ao tentar acessar a página. Tente
            novamente ou contate nosso suporte caso o erro persista.
          </S.Description>
        </S.ContainerDescription>
        <S.Button onClick={() => location.reload()}>RECARREGAR PÁGINA</S.Button>
        <S.ContactSupport
          onClick={() =>
            (window.location.href = 'https://estoca.zendesk.com/agent/')
          }
        >
          {' '}
          Contatar suporte.{' '}
        </S.ContactSupport>
      </S.ContainerCritical>
    </PageContentWrapper>
  );
};
