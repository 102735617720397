import { DayPicker as MyDayPicker } from 'react-day-picker';

import 'react-day-picker/dist/style.css';
import { styled } from '../../styles/global';

export const DayPicker = styled(MyDayPicker, {
  '--rdp-cell-size': '45px !important',
  // '--rdp-caption-font-size': '15px !important',
  '--rdp-accent-color': '#0062DC !important',
  '--rdp-background-color': 'rgba(51, 102, 255, 0.08) !important',
  '--rdp-accent-color-dark': '#0062DC !important',
  '--rdp-background-color-dark': '#0062DC !important',
  // '--rdp-outline': '1px solid var(--rdp-accent-color) !important',
  // '--rdp-outline-selected': '2px solid var(--rdp-accent-color) !important',

  margin: '0 !important',
  padding: '5px',

  borderRadius: '$sm',

  '.rdp-caption_label, .rdp-cell, .rdp-button': {
    color: '$black',
    transition: 'all .2s ease-in-out',
  },

  '.rdp-caption': {
    paddingY: '20px',
  },

  '.rdp-head': {
    borderTop: '0.8px solid #EDF1F7',
    borderBottom: '0.8px solid #EDF1F7',
  },

  '.rdp-head_cell': {
    color: '$black',
  },

  '.rdp-caption_label': {
    fontFamily: '$base',
    fontStyle: '$normal',

    fontWeight: '$bold',
    fontSize: '14px',
    lineHeight: '21px',

    textTransform: 'uppercase',
  },

  '.rdp-nav': {
    display: 'flex',
    gap: '20px',

    '.rdp-nav_button': {
      width: '20px',
      height: '20px',

      svg: {
        width: 'inherit',
        height: 'inherit',
      },
    },
  },

  '.rdp-head_cell, .rdp-cell, .rdp-button': {
    fontFamily: '$base',
    fontStyle: '$normal',

    fontWeight: '$semiBold',
    fontSize: '14px',
    lineHeight: '21px',

    textTransform: 'capitalize',
  },

  '.rdp-cell, .rdp-button': {
    borderRadius: '$sm',
  },

  '.rdp-button[disabled]:not(.rdp-day_selected)': {
    cursor: 'not-allowed',
    color: '#8F9BB3',
  },

  '.rdp-day_selected, .rdp-day_selected:focus-visible, .rdp-day_selected:hover':
    {
      color: '$black',
      border: '1px solid #0062DC',
    },

  '.rdp-button:hover:not([disabled]):not(.rdp-day_selected), .rdp-day_selected, .rdp-day_selected:focus-visible, .rdp-day_selected:hover':
    {
      background: 'rgba(51, 102, 255, 0.08)',
    },
});
