import { type IconProps } from '.';

export const AlertIcon = ({ color = '#8F9BB3', ...props }: IconProps) => {
  return (
    <svg
      className="alert-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.66662 6.16649C9.66662 5.61449 10.1146 5.1665 10.6666 5.1665C11.2187 5.1665 11.6666 5.61449 11.6666 6.16649V11.1665C11.6666 11.7185 11.2187 12.1665 10.6666 12.1665C10.1146 12.1665 9.66662 11.7185 9.66662 11.1665V6.16649ZM9.66662 14.1665C9.66662 13.6145 10.1146 13.1664 10.6666 13.1664C11.2187 13.1664 11.6666 13.6145 11.6666 14.1665C11.6666 14.7185 11.2187 15.1665 10.6666 15.1665C10.1146 15.1665 9.66662 14.7185 9.66662 14.1665ZM10.6666 18.1665C6.25563 18.1665 2.66662 14.5775 2.66662 10.1664C2.66662 5.75549 6.25563 2.1665 10.6666 2.1665C15.0776 2.1665 18.6666 5.75549 18.6666 10.1664C18.6666 14.5775 15.0776 18.1665 10.6666 18.1665ZM10.6666 0.166504C5.14362 0.166504 0.666626 4.6435 0.666626 10.1664C0.666626 15.6894 5.14362 20.1665 10.6666 20.1665C16.1897 20.1665 20.6666 15.6894 20.6666 10.1664C20.6666 4.6435 16.1897 0.166504 10.6666 0.166504Z"
        fill={color}
      />
    </svg>
  );
};
