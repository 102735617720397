import { type IconProps } from '.';

export const RingingPhone = ({ color = '#8F9BB3', ...props }: IconProps) => {
  return (
    <svg
      className="ringing-phone-icon"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill={color}
        d="M7.667 4.333a2 2 0 012 2 .667.667 0 101.333 0A3.333 3.333 0 007.667 3a.667.667 0 100 1.333z"
      />
      <path
        fill={color}
        d="M7.7 1.4a4.9 4.9 0 014.9 4.9.7.7 0 101.4 0A6.3 6.3 0 007.7 0a.7.7 0 100 1.4zM13.825 9.737a.7.7 0 00-.504-.455l-4.2-.959a.7.7 0 00-.644.182c-.098.091-.105.098-.56.966a6.937 6.937 0 01-3.409-3.423c.889-.448.896-.448.987-.553a.7.7 0 00.182-.644L4.718.7a.7.7 0 00-.455-.504A2.653 2.653 0 003.759.07 2.758 2.758 0 003.22 0 3.22 3.22 0 000 3.22 10.794 10.794 0 0010.78 14a3.22 3.22 0 003.045-4.263zM10.78 12.6A9.387 9.387 0 011.4 3.22 1.827 1.827 0 013.22 1.4h.231L4.2 4.648l-.378.196c-.602.315-1.078.567-.826 1.113a8.295 8.295 0 005.026 5.047c.588.238.819-.203 1.134-.812l.203-.385 3.241.742v.231a1.827 1.827 0 01-1.82 1.82z"
      />
    </svg>
  );
};
