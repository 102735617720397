import { type ComponentProps, useState } from 'react';

import * as S from './styles';

const requiredPropsMessageError =
  '(RadioGroup) You need to pass the `items` property for works correctly!';

export type RadioItem = {
  label: string;
  value: string;
};

export interface RadioGroupProps extends ComponentProps<typeof S.Wrapper> {
  items: RadioItem[];
  labelDirection?: 'left' | 'right';
  columnDirection?: boolean;
}

export const RadioGroup = ({
  items,
  labelDirection = 'right',
  columnDirection = false,
  ...props
}: RadioGroupProps) => {
  if (!items.length) throw new Error(requiredPropsMessageError);

  const [value, setValue] = useState<string | undefined>(
    props.defaultValue || props.value
  );

  const handleSelectedValue = (newValue: string) => {
    if (!props.disabled && newValue !== value) setValue(newValue);
  };

  return (
    <S.Wrapper
      className="radio-group-wrapper"
      isColumnDirection={!!columnDirection}
      {...props}
      value={value}
    >
      {items.map(item => (
        <S.Item
          className="radio-item"
          key={item.value}
          disabled={!!props.disabled}
        >
          {labelDirection === 'left' && (
            <S.Label htmlFor={item.label} disabled={!!props.disabled}>
              {item.label}
            </S.Label>
          )}

          <S.Circle
            id={item.label}
            value={item.value}
            checked={item.value === value}
            disabled={!!props.disabled}
            onClick={() => handleSelectedValue(item.value)}
          >
            <S.Indicator
              checked={item.value === value}
              disabled={!!props.disabled}
            />
          </S.Circle>

          {labelDirection === 'right' && (
            <S.Label htmlFor={item.label} disabled={!!props.disabled}>
              {item.label}
            </S.Label>
          )}
        </S.Item>
      ))}
    </S.Wrapper>
  );
};
