import { type IconProps } from '.';

interface SortIconProps extends IconProps {
  upArrowColor: string;
  downArrowColor: string;
}

export const SortIcon = ({
  upArrowColor,
  downArrowColor,
  ...props
}: SortIconProps) => {
  return (
    <svg
      width="9"
      height="12"
      viewBox="0 0 9 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.000117524 4.05488C0.000547524 3.94263 0.0236473 3.83162 0.0680373 3.72851C0.112437 3.62541 0.177197 3.53234 0.258447 3.45488L3.59179 0.23821C3.74756 0.0855302 3.95699 0 4.17511 0C4.39321 0 4.60271 0.0855302 4.75841 0.23821L8.09181 3.57155C8.16951 3.64925 8.23111 3.74149 8.27321 3.84301C8.31521 3.94453 8.33691 4.05333 8.33691 4.16321C8.33691 4.2731 8.31521 4.3819 8.27321 4.48342C8.23111 4.58494 8.16951 4.67718 8.09181 4.75488C8.01411 4.83258 7.92181 4.89421 7.82031 4.93626C7.71881 4.97831 7.61001 4.99996 7.50011 4.99996C7.39021 4.99996 7.28141 4.97831 7.17991 4.93626C7.07841 4.89421 6.98611 4.83258 6.90841 4.75488L4.16681 2.00488L1.41679 4.65488C1.26065 4.81009 1.04944 4.89721 0.829287 4.89721C0.609127 4.89721 0.397917 4.81009 0.241787 4.65488C0.163547 4.57603 0.101867 4.48232 0.0603573 4.37929C0.0188573 4.27625 -0.00162248 4.16595 0.000117524 4.05488Z"
        fill={upArrowColor}
      />
      <path
        d="M8.3367 7.9451C8.3363 8.0573 8.3132 8.1683 8.2688 8.2714C8.2244 8.3745 8.1597 8.4676 8.0784 8.5451L4.7451 11.7617C4.5893 11.9144 4.3799 12 4.1617 12C3.94362 12 3.73418 11.9144 3.57841 11.7617L0.24508 8.4284C0.16738 8.3507 0.10574 8.2585 0.0636902 8.157C0.0216402 8.0554 0 7.9466 0 7.8367C0 7.7269 0.0216402 7.6181 0.0636902 7.5165C0.10574 7.415 0.16738 7.3228 0.24508 7.2451C0.32278 7.1674 0.41502 7.1057 0.51654 7.0637C0.61805 7.0216 0.72686 7 0.83674 7C0.94663 7 1.05543 7.0216 1.15695 7.0637C1.25847 7.1057 1.35071 7.1674 1.42841 7.2451L4.1701 9.9951L6.9201 7.3451C7.0762 7.1899 7.2874 7.1027 7.5076 7.1027C7.7277 7.1027 7.9389 7.1899 8.0951 7.3451C8.1733 7.4239 8.235 7.5176 8.2765 7.6207C8.318 7.7237 8.3385 7.834 8.3367 7.9451Z"
        fill={downArrowColor}
      />
    </svg>
  );
};
