import { type IconProps } from '.';

export const ExternalIcon = ({ color = '#8F9BB3', ...props }: IconProps) => {
  return (
    <svg
      className="external-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_1_2)">
        <path
          d="M19.0761 0.221848H13.2203C12.71 0.221848 12.2964 0.635464 12.2964 1.14579C12.2964 1.65611 12.71 2.06973 13.2203 2.06973H16.8455L7.98591 10.9293C7.62506 11.2901 7.62506 11.8751 7.98591 12.2359C8.16638 12.4163 8.40281 12.5065 8.63924 12.5065C8.87566 12.5065 9.11209 12.4163 9.29256 12.2359L18.1521 3.37638V7.00161C18.1521 7.51193 18.5657 7.92555 19.0761 7.92555C19.5864 7.92555 20 7.51193 20 7.00161V1.14589C20 0.635464 19.5864 0.221848 19.0761 0.221848Z"
          fill={color}
        />
        <path
          d="M15.2963 7.92545C14.786 7.92545 14.3724 8.33907 14.3724 8.84939V17.9303H1.84788V5.40577H11.4751C11.9854 5.40577 12.3991 4.99215 12.3991 4.48183C12.3991 3.97151 11.9854 3.55789 11.4751 3.55789H0.923939C0.413617 3.55789 0 3.97151 0 4.48183V18.8542C0 19.3645 0.413617 19.7782 0.923939 19.7782H15.2963C15.8066 19.7782 16.2203 19.3645 16.2203 18.8542V8.84939C16.2203 8.33907 15.8066 7.92545 15.2963 7.92545Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1_2">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
