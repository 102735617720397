import { type IconProps } from '.';

export const CalendarIcon = ({ color = '#8F9BB3', ...props }: IconProps) => {
  return (
    <svg
      className="calendar-icon"
      width="15"
      height="17"
      fill="none"
      viewBox="0 0 15 17"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill={color}
        d="M12.5 1.667h-.833V.833a.833.833 0 10-1.667 0v.834H5V.833a.833.833 0 00-1.667 0v.834H2.5a2.5 2.5 0 00-2.5 2.5v10a2.5 2.5 0 002.5 2.5h10a2.5 2.5 0 002.5-2.5v-10a2.5 2.5 0 00-2.5-2.5zm-10 1.666h.833v.834a.833.833 0 101.667 0v-.834h5v.834a.833.833 0 101.667 0v-.834h.833a.833.833 0 01.833.834V7.5H1.667V4.167a.833.833 0 01.833-.834zM12.5 15h-10a.833.833 0 01-.833-.833v-5h11.666v5A.834.834 0 0112.5 15z"
      />
      <path
        fill={color}
        d="M4.167 12.5a.833.833 0 100-1.667.833.833 0 000 1.667zM10.833 10.833H7.5a.833.833 0 100 1.667h3.333a.833.833 0 000-1.667z"
      />
    </svg>
  );
};
