import * as ToggleGroup from '@radix-ui/react-toggle-group';

import { styled } from '../../styles/global';

export const Root = styled(ToggleGroup.Root, {
  display: 'inline-flex',
  backgroundColor: 'transparent',

  borderRadius: '$md',
});

export const Item = styled(ToggleGroup.Item, {
  all: 'unset',
  backgroundColor: 'inherit',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  padding: '8px',
  gap: '10px',

  minWidth: '32px',
  minHeight: '32px',

  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: '$primary',

  cursor: 'pointer',

  transition: 'brightness .2s ease-in-out',

  '&:hover': {
    filter: 'brightness(0.95)',
  },

  '&:disabled, &[disabled], &[data-disabled]': {
    cursor: 'not-allowed',
  },

  '&:first-child': {
    borderTopLeftRadius: '$md',
    borderBottomLeftRadius: '$md',

    borderRight: 'none',
  },

  '&:last-child': {
    borderTopRightRadius: '$md',
    borderBottomRightRadius: '$md',

    borderLeft: 'none',
  },

  '&[data-state=off]:not(:last-child)': {
    borderRight: 'none',
  },

  '&[data-state=on]': {
    backgroundColor: '$primary',
  },
});
