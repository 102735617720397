import { type IconProps } from '.';

export const CheckmarkIcon = ({ color = '#8F9BB3', ...props }: IconProps) => {
  return (
    <svg
      className="checkmark-icon"
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.86338 12.0001C5.58738 12.0001 5.32338 11.8861 5.13438 11.6851L0.271382 6.5061C-0.107618 6.1041 -0.0866179 5.4711 0.315382 5.0931C0.718382 4.7151 1.35138 4.7351 1.72838 5.1371L5.85338 9.5281L14.2614 0.326113C14.6354 -0.0828874 15.2674 -0.109887 15.6754 0.262113C16.0824 0.634113 16.1104 1.26711 15.7384 1.67411L6.6014 11.6741C6.4144 11.8801 6.1484 11.9981 5.87038 12.0001H5.86338Z"
        fill={color}
      />
    </svg>
  );
};
