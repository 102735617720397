import { type IconProps } from '.';

export const OutlineRoundedCheckmarkIcon = ({
  color = '#8F9BB3',
  ...props
}: IconProps) => {
  return (
    <svg
      className="outline-rounded-checkmark-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      {...props}
    >
      <path
        d="M10.6666 0.166687C8.68886 0.166687 6.75546 0.753173 5.11097 1.85199C3.46649 2.9508 2.18477 4.51257 1.4279 6.33983C0.671023 8.16709 0.472988 10.1778 0.858835 12.1176C1.24469 14.0574 2.19709 15.8391 3.59562 17.2377C4.99414 18.6362 6.77596 19.5886 8.71576 19.9744C10.6556 20.3604 12.6662 20.1622 14.4935 19.4054C16.3208 18.6486 17.8826 17.3669 18.9813 15.7224C20.0801 14.0779 20.6667 12.1445 20.6667 10.1666C20.6667 8.85344 20.408 7.55309 19.9054 6.33983C19.4028 5.12657 18.6663 4.0242 17.7377 3.0956C16.8092 2.16702 15.7067 1.43043 14.4935 0.927881C13.2802 0.425346 11.9799 0.166687 10.6666 0.166687ZM10.6666 18.1666C9.08441 18.1666 7.5377 17.6974 6.22211 16.8185C4.90652 15.9393 3.88115 14.6899 3.27565 13.2281C2.67015 11.7663 2.51172 10.1578 2.82041 8.60594C3.12908 7.05409 3.89101 5.62864 5.00983 4.50982C6.12864 3.39101 7.55411 2.62908 9.10594 2.32039C10.6578 2.01172 12.2663 2.17014 13.7281 2.77564C15.1899 3.38114 16.4393 4.40651 17.3185 5.72211C18.1975 7.0377 18.6667 8.58441 18.6667 10.1666C18.6667 12.2883 17.8238 14.3232 16.3235 15.8235C14.8232 17.3238 12.7883 18.1666 10.6666 18.1666Z"
        fill={color}
      />
      <path
        d="M13.3666 6.55663L9.58662 11.5566L7.95663 9.44661C7.79353 9.2371 7.55386 9.10096 7.29038 9.06814C7.02688 9.03532 6.76116 9.10852 6.55164 9.27162C6.34212 9.43472 6.20597 9.6744 6.17315 9.93792C6.14033 10.2013 6.21353 10.4671 6.37663 10.6766L8.80664 13.7867C8.9008 13.9058 9.02088 14.0019 9.15777 14.0678C9.29465 14.1336 9.44473 14.1674 9.59663 14.1666C9.74934 14.1662 9.89995 14.1309 10.0369 14.0633C10.1738 13.9957 10.2935 13.8977 10.3866 13.7766L14.9566 7.77663C15.1184 7.56446 15.1893 7.2967 15.1537 7.03228C15.118 6.76785 14.9788 6.52842 14.7667 6.36663C14.5545 6.20485 14.2867 6.13398 14.0223 6.1696C13.7578 6.20524 13.5184 6.34446 13.3567 6.55663H13.3666Z"
        fill={color}
      />
    </svg>
  );
};
