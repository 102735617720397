import { type IconProps } from '.';

export const InfoIcon = ({ color = '#8F9BB3', ...props }: IconProps) => {
  return (
    <svg
      className="info-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      {...props}
    >
      <path
        d="M10.6666 0.166687C8.68882 0.166687 6.75542 0.753175 5.11092 1.85199C3.46643 2.95081 2.18471 4.51258 1.42784 6.33984C0.670962 8.16711 0.472926 10.1778 0.858774 12.1176C1.24463 14.0574 2.19704 15.8392 3.59556 17.2378C4.99408 18.6362 6.77591 19.5887 8.71572 19.9745C10.6555 20.3604 12.6662 20.1623 14.4935 19.4054C16.3207 18.6486 17.8825 17.3669 18.9813 15.7224C20.0802 14.0779 20.6666 12.1445 20.6666 10.1667C20.6666 8.85346 20.4079 7.5531 19.9055 6.33984C19.4029 5.12658 18.6662 4.02421 17.7377 3.09561C16.8091 2.16703 15.7067 1.43043 14.4935 0.927882C13.2801 0.425347 11.9798 0.166687 10.6666 0.166687ZM10.6666 18.1667C9.08437 18.1667 7.53766 17.6975 6.22206 16.8185C4.90647 15.9394 3.88109 14.6899 3.2756 13.2281C2.67009 11.7664 2.51167 10.1578 2.82034 8.60596C3.12903 7.05411 3.89096 5.62865 5.00978 4.50983C6.1286 3.39101 7.55405 2.62909 9.1059 2.3204C10.6578 2.01172 12.2663 2.17015 13.7281 2.77564C15.1899 3.38115 16.4393 4.40652 17.3184 5.72212C18.1974 7.03772 18.6666 8.58443 18.6666 10.1667C18.6666 12.2884 17.8238 14.3232 16.3235 15.8236C14.8232 17.3238 12.7884 18.1667 10.6666 18.1667Z"
        fill={color}
      />
      <path
        d="M10.6667 5.16665C10.1144 5.16665 9.66666 5.61437 9.66666 6.16666C9.66666 6.71894 10.1144 7.16666 10.6667 7.16666C11.2189 7.16666 11.6666 6.71894 11.6666 6.16666C11.6666 5.61437 11.2189 5.16665 10.6667 5.16665Z"
        fill={color}
      />
      <path
        d="M10.6667 15.1667C10.9319 15.1667 11.1863 15.0613 11.3737 14.8738C11.5613 14.6862 11.6666 14.4319 11.6666 14.1667V9.16669C11.6666 8.90146 11.5613 8.64711 11.3737 8.45957C11.1863 8.27204 10.9319 8.16668 10.6667 8.16668C10.4014 8.16668 10.1471 8.27204 9.95954 8.45957C9.772 8.64711 9.66666 8.90146 9.66666 9.16669V14.1667C9.66666 14.4319 9.772 14.6862 9.95954 14.8738C10.1471 15.0613 10.4014 15.1667 10.6667 15.1667Z"
        fill={color}
      />
    </svg>
  );
};
