import { styled } from '../../styles/global';

export const Wrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  '@medium': {
    flexDirection: 'column',
    justifyContent: 'center',

    gap: '16px',
  },
});

export const Description = styled('p', {
  display: 'flex',
  gap: '10px',

  color: '$black',

  fontFamily: '$base',
  fontStyle: '$normal',
  fontWeight: '$regular',
  fontSize: '14px',
  lineHeight: '21px',
});

export const RowsPerPageSelect = styled('select', {
  cursor: 'pointer',

  paddingX: '4px',

  borderRadius: '$sm',
  border: '1px solid #E5EBF5',

  backgroundColor: '#F6F6F6',

  color: '$black',

  fontFamily: '$base',
  fontStyle: '$normal',
  fontWeight: '$regular',
  fontSize: '14px',
  lineHeight: '21px',

  '&:hover': {
    filter: 'brightness(0.95)',
  },
});

export const ButtonsWrapper = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',

  borderRadius: '$md',
  border: '1px solid #E5EBF5',
  backgroundColor: '$white',

  '@medium': {
    width: '100%',
  },
});

export const PageButton = styled('button', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  padding: '12px',

  width: '44px',
  height: '44px',

  backgroundColor: '$white',
  color: '$primary',

  '&:disabled, &[disabled]': {
    backgroundColor: '#8F9BB3',

    'svg path': {
      fill: '#E5EBF5',
    },
  },

  fontFamily: '$base',
  fontStyle: '$normal',

  fontWeight: '$regular',
  fontSize: '14px',
  lineHeight: '20px',

  '&:first-child': {
    borderRadius: '$md 0 0 $md',
  },

  '&:last-child': {
    borderRadius: '0 $md $md 0',
  },

  '@medium': {
    width: '100%',
  },

  variants: {
    active: {
      true: {
        backgroundColor: '$primary',
        color: '$white',
      },
    },
    separator: {
      true: {
        pointerEvents: 'none',
      },
    },
  },
});
