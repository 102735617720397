import { styled } from '../../styles/global';
import { theme } from '../../styles/theme';
import { type CSSProperties } from '@stitches/react';

import { type AlertVariant } from '.';

export const variantColors: {
  [key in AlertVariant]: CSSProperties['color'];
} = {
  info: theme.colors.primary,
  warning: '#FFA800',
  success: '#14C771',
  error: '#DD3535',
  abstract: '#8F9BB3',
};

export const Wrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',

  padding: '16px',
  gap: '16px',

  borderRadius: '$md',

  borderWidth: '1px',
  borderStyle: 'solid',

  '.icon': {
    width: '20px',
    height: '20px',
  },

  variants: {
    variant: {
      info: {
        borderColor: variantColors.info,
        backgroundColor: 'rgba(0, 98, 220, 0.10)',
      },
      warning: {
        borderColor: variantColors.warning,
        backgroundColor: 'rgba(255, 168, 0, 0.10)',
      },
      success: {
        borderColor: variantColors.success,
        backgroundColor: 'rgba(20, 199, 113, 0.10)',
      },
      error: {
        borderColor: variantColors.error,
        backgroundColor: 'rgba(221, 53, 53, 0.10)',
      },
      abstract: {
        borderColor: variantColors.abstract,
        backgroundColor: 'rgba(143, 155, 179, 0.10)',
      },
    },
  },
});

export const Column = styled('h1', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
});

export const Title = styled('h1', {
  color: '$black',

  fontFamily: '$base',
  fontStyle: '$normal',
  fontWeight: '$bold',
  fontSize: '14px',
  lineHeight: '21px',
});

export const Description = styled('p', {
  color: '$black',

  fontFamily: '$base',
  fontStyle: '$normal',
  fontWeight: '$regular',
  fontSize: '12px',
  lineHeight: '18px',
});
