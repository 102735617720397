import { type IconProps } from '.';

export const BarChartIcon = ({ color = '#8F9BB3', ...props }: IconProps) => {
  return (
    <svg
      className="bar-chart-icon"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0002 3.33331C9.54183 3.33331 9.16683 3.70831 9.16683 4.16665V16.6666C9.16683 17.125 9.54183 17.5 10.0002 17.5C10.4585 17.5 10.8335 17.125 10.8335 16.6666V4.16665C10.8335 3.70831 10.4585 3.33331 10.0002 3.33331ZM15.8335 9.99998C15.3752 9.99998 15.0002 10.375 15.0002 10.8333V16.6666C15.0002 17.125 15.3752 17.5 15.8335 17.5C16.2918 17.5 16.6668 17.125 16.6668 16.6666V10.8333C16.6668 10.375 16.2918 9.99998 15.8335 9.99998ZM3.3335 7.49998C3.3335 7.04165 3.7085 6.66665 4.16683 6.66665C4.62516 6.66665 5.00016 7.04165 5.00016 7.49998V16.6666C5.00016 17.125 4.62516 17.5 4.16683 17.5C3.7085 17.5 3.3335 17.125 3.3335 16.6666V7.49998Z"
        fill={color}
      />
      <mask
        id="mask0_371_13008"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="3"
        y="3"
        width="14"
        height="15"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0002 3.33331C9.54183 3.33331 9.16683 3.70831 9.16683 4.16665V16.6666C9.16683 17.125 9.54183 17.5 10.0002 17.5C10.4585 17.5 10.8335 17.125 10.8335 16.6666V4.16665C10.8335 3.70831 10.4585 3.33331 10.0002 3.33331ZM15.8335 9.99998C15.3752 9.99998 15.0002 10.375 15.0002 10.8333V16.6666C15.0002 17.125 15.3752 17.5 15.8335 17.5C16.2918 17.5 16.6668 17.125 16.6668 16.6666V10.8333C16.6668 10.375 16.2918 9.99998 15.8335 9.99998ZM3.3335 7.49998C3.3335 7.04165 3.7085 6.66665 4.16683 6.66665C4.62516 6.66665 5.00016 7.04165 5.00016 7.49998V16.6666C5.00016 17.125 4.62516 17.5 4.16683 17.5C3.7085 17.5 3.3335 17.125 3.3335 16.6666V7.49998Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_371_13008)">
        <rect width="20" height="20" fill={color} />
      </g>
    </svg>
  );
};
