import { type IconProps } from '.';

export const HomeIcon = ({ color = '#8F9BB3', ...props }: IconProps) => {
  return (
    <svg
      className="home-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M17.0167 8.48333L10.5917 1.91666C10.5142 1.83856 10.422 1.77656 10.3205 1.73426C10.2189 1.69195 10.11 1.67017 10 1.67017C9.88999 1.67017 9.78107 1.69195 9.67952 1.73426C9.57797 1.77656 9.48581 1.83856 9.40834 1.91666L2.98334 8.49166C2.82787 8.64839 2.70508 8.83444 2.62211 9.039C2.53914 9.24357 2.49763 9.46259 2.5 9.68333V16.6667C2.49936 17.0933 2.66232 17.5039 2.95532 17.8139C3.24832 18.124 3.64906 18.3099 4.075 18.3333H15.925C16.351 18.3099 16.7517 18.124 17.0447 17.8139C17.3377 17.5039 17.5006 17.0933 17.5 16.6667V9.68333C17.5007 9.23578 17.3274 8.80548 17.0167 8.48333ZM8.33334 16.6667V11.6667H11.6667V16.6667H8.33334ZM15.8333 16.6667H13.3333V10.8333C13.3333 10.6123 13.2455 10.4004 13.0893 10.2441C12.933 10.0878 12.721 10 12.5 10H7.5C7.27899 10 7.06703 10.0878 6.91075 10.2441C6.75447 10.4004 6.66667 10.6123 6.66667 10.8333V16.6667H4.16667V9.65L10 3.69166L15.8333 9.68333V16.6667Z"
        fill={color}
      />
    </svg>
  );
};
