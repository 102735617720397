import { type IconProps } from '.';

export const GeneralInfoIcon = ({ color = '#8F9BB3', ...props }: IconProps) => {
  return (
    <svg
      className="general-info-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="10"
      viewBox="0 0 15 10"
      fill="none"
      {...props}
    >
      <path
        fill={color}
        d="M.8 1.6A.8.8 0 10.8 0a.8.8 0 000 1.6zM.8 5.6A.8.8 0 10.8 4a.8.8 0 000 1.6zM.8 9.6A.8.8 0 10.8 8a.8.8 0 000 1.6zM13.648 4H3.952a.752.752 0 00-.752.752v.096c0 .415.337.752.752.752h9.696a.752.752 0 00.752-.752v-.096A.752.752 0 0013.648 4zM13.648 8H3.952a.752.752 0 00-.752.752v.096c0 .415.337.752.752.752h9.696a.752.752 0 00.752-.752v-.096A.752.752 0 0013.648 8zM13.648 0H3.952A.752.752 0 003.2.752v.096c0 .415.337.752.752.752h9.696A.752.752 0 0014.4.848V.752A.752.752 0 0013.648 0z"
      />
    </svg>
  );
};
