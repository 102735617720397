import { type IconProps } from '.';

export const OrderInfoIcon = ({ color = '#8F9BB3', ...props }: IconProps) => {
  return (
    <svg
      className="order-info-icon"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 12H2C1.46957 12 0.96086 11.7893 0.58579 11.4142C0.21071 11.0391 0 10.5304 0 10V2C0 1.46957 0.21071 0.96086 0.58579 0.58579C0.96086 0.21071 1.46957 0 2 0H10C10.5304 0 11.0391 0.21071 11.4142 0.58579C11.7893 0.96086 12 1.46957 12 2V10C12 10.5304 11.7893 11.0391 11.4142 11.4142C11.0391 11.7893 10.5304 12 10 12ZM2 1.33333C1.82319 1.33333 1.65362 1.40357 1.5286 1.5286C1.40357 1.65362 1.33333 1.82319 1.33333 2V10C1.33333 10.1768 1.40357 10.3464 1.5286 10.4714C1.65362 10.5964 1.82319 10.6667 2 10.6667H10C10.1768 10.6667 10.3464 10.5964 10.4714 10.4714C10.5964 10.3464 10.6667 10.1768 10.6667 10V2C10.6667 1.82319 10.5964 1.65362 10.4714 1.5286C10.3464 1.40357 10.1768 1.33333 10 1.33333H2Z"
        fill={color}
      />
      <path d="M8.6667 4H6V10.6667H8.6667V4Z" fill={color} />
    </svg>
  );
};
