import * as MyTabs from '@radix-ui/react-tabs';

import { styled } from '../../styles/global';

export const Root = styled(MyTabs.Root, {
  display: 'flex',
  flexDirection: 'column',
});

export const List = styled(MyTabs.List, {
  flexShrink: 0,
  display: 'flex',
});

const borderRadiusValue = 2;

export const Trigger = styled(MyTabs.Trigger, {
  all: 'unset',

  cursor: 'pointer',

  minWidth: '65px',
  minHeight: '50px',

  flex: 1,

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  gap: '4px',

  isolation: 'isolate',

  fontFamily: '$base',
  fontStyle: '$normal',
  fontWeight: '$regular',
  fontSize: '12px',
  lineHeight: '18px',

  backgroundColor: 'inherit',

  color: '$darkGrey',
  userSelect: 'none',

  '&:first-child': {
    borderTopLeftRadius: borderRadiusValue,
  },

  '&:last-child': {
    borderTopRightRadius: borderRadiusValue,
  },

  '&[data-state="active"]': {
    fontWeight: '$semiBold',
    color: '$primary',

    borderBottom: '2px solid #0062DC',
    boxShadow: 'inset 0 -1px 0 0 #0062DC, 0 1px 0 0 #0062DC',
  },

  '&:focus': {
    position: 'relative',
    boxShadow: '0 0 0 2px #0062DC',
  },

  '&:disabled': {
    cursor: 'not-allowed',
  },

  '[class*="icon"]': {
    width: '16px',
    height: '16px',
  },
});

export const Content = styled(MyTabs.Content, {
  paddingTop: borderRadiusValue,

  flexGrow: 1,
  borderBottomLeftRadius: borderRadiusValue,
  borderBottomRightRadius: borderRadiusValue,

  backgroundColor: 'transparent',
});
