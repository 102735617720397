import { styled } from '../../styles/global';

import { rotation } from './animations';

export const Loading = styled('span', {
  boxSizing: 'border-box',

  display: 'inline-block',

  borderRadius: '$circular',

  borderTopWidth: '3px',
  borderTopStyle: 'solid',

  borderRight: '3px solid transparent',

  animation: `${rotation} 1s linear infinite`,
});
