import { type IconProps } from '.';

export const SaleIcon = ({ color = '#8F9BB3', ...props }: IconProps) => {
  return (
    <svg
      className="sale-icon"
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M1 4.2V2.6c0-.424.164-.831.456-1.131.291-.3.687-.469 1.1-.469H4.11M1 4.2c1.037 0 3.111-.64 3.111-3.2M1 4.2v3.2M4.111 1h7.778M1 7.4V9c0 .424.164.831.456 1.131.291.3.687.469 1.1.469H4.11M1 7.4c1.037 0 3.111.64 3.111 3.2M11.89 1h1.555c.413 0 .809.169 1.1.469.292.3.456.707.456 1.131v1.6M11.889 1c0 2.56 2.074 3.2 3.111 3.2M4.111 10.6h3.111M15 4.2v1.6m-2.333 2.4v2.4m0 0V13m0-2.4h-2.334m2.334 0H15M9.556 5.8c0 .884-.697 1.6-1.556 1.6-.86 0-1.556-.716-1.556-1.6 0-.884.697-1.6 1.556-1.6.86 0 1.556.716 1.556 1.6z"
      />
    </svg>
  );
};
