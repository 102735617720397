import { type IconProps } from '.';

export const ChatIcon = ({ color = '#8F9BB3', ...props }: IconProps) => {
  return (
    <svg
      className="chat-icon"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.99998 7.66671C7.36817 7.66671 7.66665 7.36823 7.66665 7.00004C7.66665 6.63185 7.36817 6.33337 6.99998 6.33337C6.63179 6.33337 6.33331 6.63185 6.33331 7.00004C6.33331 7.36823 6.63179 7.66671 6.99998 7.66671Z"
        fill={color}
      />
      <path
        d="M9.6667 7.66671C10.0349 7.66671 10.3333 7.36823 10.3333 7.00004C10.3333 6.63185 10.0349 6.33337 9.6667 6.33337C9.2985 6.33337 9 6.63185 9 7.00004C9 7.36823 9.2985 7.66671 9.6667 7.66671Z"
        fill={color}
      />
      <path
        d="M4.33335 7.66671C4.70154 7.66671 5.00002 7.36823 5.00002 7.00004C5.00002 6.63185 4.70154 6.33337 4.33335 6.33337C3.96516 6.33337 3.66669 6.63185 3.66669 7.00004C3.66669 7.36823 3.96516 7.66671 4.33335 7.66671Z"
        fill={color}
      />
      <path
        d="M11.7133 2.28666C10.6194 1.18566 9.1757 0.500797 7.63104 0.350157C6.08636 0.199507 4.53752 0.592507 3.25155 1.46142C1.96557 2.33032 1.02305 3.62067 0.586466 5.11C0.149886 6.59934 0.246606 8.19433 0.859956 9.62C0.923876 9.7525 0.944856 9.9017 0.919956 10.0467L0.333286 12.8667C0.310686 12.9748 0.315296 13.0868 0.346716 13.1927C0.378136 13.2986 0.435376 13.395 0.513286 13.4733C0.577156 13.5367 0.653206 13.5866 0.736846 13.6198C0.820486 13.653 0.909986 13.669 0.999956 13.6667H1.13329L3.98662 13.0933C4.13164 13.0759 4.2787 13.0966 4.41329 13.1533C5.83896 13.7667 7.43395 13.8634 8.92328 13.4268C10.4126 12.9902 11.703 12.0477 12.5719 10.7617C13.4408 9.4758 13.8338 7.92693 13.6831 6.38225C13.5325 4.83757 12.8476 3.39385 11.7466 2.29999L11.7133 2.28666ZM12.2666 7.85999C12.1363 8.65598 11.8271 9.412 11.3623 10.0713C10.8976 10.7305 10.2893 11.2757 9.5834 11.6659C8.87746 12.0561 8.09222 12.2811 7.28677 12.3239C6.48132 12.3668 5.67664 12.2264 4.93329 11.9133C4.66965 11.8012 4.38644 11.7423 4.09996 11.74C3.97481 11.7409 3.84995 11.752 3.72662 11.7733L1.84662 12.1533L2.22662 10.2733C2.30231 9.8662 2.25352 9.4457 2.08662 9.0667C1.77355 8.32331 1.63316 7.51863 1.67602 6.71318C1.71888 5.90773 1.94386 5.12249 2.33405 4.41656C2.72424 3.71063 3.26946 3.10239 3.92869 2.63763C4.58791 2.17286 5.34397 1.86367 6.13996 1.73333C6.97547 1.59621 7.83163 1.65998 8.63761 1.91937C9.4436 2.17876 10.1762 2.62632 10.7749 3.22502C11.3736 3.82372 11.8212 4.55636 12.0806 5.36234C12.34 6.16832 12.4037 7.02448 12.2666 7.85999Z"
        fill={color}
      />
    </svg>
  );
};
