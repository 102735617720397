import { type CSSProperties } from '@stitches/react';

import { styled } from '../../styles/global';

export const Wrapper = styled('div', {
  position: 'relative',

  marginBottom: '44px',
});

export const StatusCheck = styled('span', {
  position: 'absolute',

  top: '50%',
  transform: 'translateY(-50%)',

  width: '12px',
  height: '12px',

  borderStyle: 'solid',
  borderRadius: '$lg',

  userSelect: 'none',
  cursor: 'pointer',

  variants: {
    isActiveStepChecked: {
      false: {
        borderWidth: '2px',

        borderColor: '#E5EBF5',
        backgroundColor: '$white',
      },
      true: {
        borderWidth: '1px',

        borderColor: '$primary',
        backgroundColor: '$primary',

        '.label': {
          color: '$primary',
          fontWeight: '$bold',

          '&::after': {
            display: 'block',
            content: 'attr(data-date)',

            color: '#8F9BB3',

            fontStyle: '$normal',
            fontFamily: '$base',
            fontWeight: '$semiBold',
            fontSize: '8px',
            lineHeight: '13px',
          },
        },
      },
    },
    isActiveStatus: {
      true: {
        width: '16px',
        height: '16px',

        borderWidth: '2px',
        borderColor: '$primary',
      },
    },
    isUncompletedStep: {
      true: {
        backgroundColor: '$white',
      },
    },
    hasActionClick: {
      false: {
        cursor: 'not-allowed',
      },
    },
  },
});

const topDistance: CSSProperties['top'] = '12px';

export const StatusCheckLabel = styled('span', {
  position: 'absolute',

  top: `calc(50% + ${topDistance})`,
  left: '50%',
  transform: `translate(-50%, calc(-50% + ${topDistance}))`,

  color: '#495057',
  textAlign: 'center',

  fontStyle: '$normal',
  fontFamily: '$base',
  fontWeight: '$semiBold',
  fontSize: '10px',
  lineHeight: '15px',

  whiteSpace: 'nowrap',
});
