import { type IconProps } from '.';

export const ArrowLeftIcon = ({ color = '#8F9BB3', ...props }: IconProps) => {
  return (
    <svg
      className="arrow-left-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="7"
      height="10"
      viewBox="0 0 7 10"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.36228 10C5.10028 10 4.83928 9.89801 4.64328 9.69501L0.780284 5.69501C0.402284 5.30201 0.407284 4.67901 0.793284 4.29301L4.79328 0.293006C5.18328 -0.0979941 5.81628 -0.0979941 6.20728 0.293006C6.59728 0.684006 6.59728 1.31601 6.20728 1.70701L2.90228 5.01201L6.08128 8.30501C6.46528 8.70301 6.45428 9.33601 6.05728 9.71901C5.86228 9.90701 5.61228 10 5.36228 10Z"
        fill={color}
      />
    </svg>
  );
};
