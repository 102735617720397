import { useEffect } from 'react';

import { type DocumentInfo } from '../components/Navbar';

export const useDocumentTitle = (info: DocumentInfo) =>
  useEffect(() => {
    if (info.title) {
      document.title = `${info.title} ${
        info.description && `- ${info.description}`
      } | Estoca`;
    }
  }, [info]);
