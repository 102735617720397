import { styled } from '../../styles/global';

export const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',

  input: {
    minHeight: '37px',
    padding: '8px 12px',

    color: '$gray',

    fontFamily: '$base',
    fontStyle: '$normal',
    fontWeight: '$regular',
    fontSize: '14px',
    lineHeight: '21px',

    border: '1px solid #E5EBF5',
    borderRadius: '$md',

    outlineColor: '$primary',

    '&:disabled, &[disabled]': {
      cursor: 'not-allowed',
    },

    '&::placeholder': {
      color: '$gray',
    },
  },

  variants: {
    error: {
      true: {
        input: {
          borderColor: '$unsuccess',
        },
      },
    },
  },
});

export const Label = styled('label', {
  display: 'flex',
  gap: '4px',

  color: '$darkGrey',

  fontFamily: '$base',
  fontStyle: '$normal',
  fontWeight: '$semiBold',
  fontSize: '12px',
  lineHeight: '18px',

  '[class*="icon"]': {
    width: '16px',
    height: '16px',
  },
});
