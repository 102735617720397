import { type IconProps } from '.';

export const DownloadIcon = ({ color = '#8F9BB3', ...props }: IconProps) => {
  return (
    <svg
      className="download-icon"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.16919 10.0632C9.16763 10.0424 9.16683 10.0213 9.16683 10V3.33333C9.16683 2.87333 9.53933 2.5 10.0002 2.5C10.461 2.5 10.8335 2.87333 10.8335 3.33333V9.99985L12.8335 8.5C13.2018 8.2225 13.7243 8.29833 14.0002 8.66667C14.2768 9.035 14.2018 9.5575 13.8335 9.83333L10.5002 12.3333C10.3527 12.4442 10.176 12.5 10.0002 12.5C9.83266 12.5 9.66516 12.4492 9.521 12.3483L6.18766 10.0033C5.811 9.73833 5.72016 9.21833 5.98516 8.8425C6.25016 8.46583 6.76933 8.375 7.146 8.64L9.16919 10.0632ZM5.00016 14.1667V15H15.0002V14.1667C15.0002 13.7083 15.3752 13.3333 15.8335 13.3333C16.2918 13.3333 16.6668 13.7083 16.6668 14.1667L16.6668 15.8333C16.6668 16.2917 16.2918 16.6667 15.8335 16.6667L4.16683 16.6667C3.7085 16.6667 3.3335 16.2917 3.3335 15.8333V14.1667C3.3335 13.7083 3.7085 13.3333 4.16683 13.3333C4.62516 13.3333 5.00016 13.7083 5.00016 14.1667Z"
        fill={color}
      />
      <mask
        id="mask0_371_13013"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="3"
        y="2"
        width="14"
        height="15"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.16919 10.0632C9.16763 10.0424 9.16683 10.0213 9.16683 10V3.33333C9.16683 2.87333 9.53933 2.5 10.0002 2.5C10.461 2.5 10.8335 2.87333 10.8335 3.33333V9.99985L12.8335 8.5C13.2018 8.2225 13.7243 8.29833 14.0002 8.66667C14.2768 9.035 14.2018 9.5575 13.8335 9.83333L10.5002 12.3333C10.3527 12.4442 10.176 12.5 10.0002 12.5C9.83266 12.5 9.66516 12.4492 9.521 12.3483L6.18766 10.0033C5.811 9.73833 5.72016 9.21833 5.98516 8.8425C6.25016 8.46583 6.76933 8.375 7.146 8.64L9.16919 10.0632ZM5.00016 14.1667V15H15.0002V14.1667C15.0002 13.7083 15.3752 13.3333 15.8335 13.3333C16.2918 13.3333 16.6668 13.7083 16.6668 14.1667L16.6668 15.8333C16.6668 16.2917 16.2918 16.6667 15.8335 16.6667L4.16683 16.6667C3.7085 16.6667 3.3335 16.2917 3.3335 15.8333V14.1667C3.3335 13.7083 3.7085 13.3333 4.16683 13.3333C4.62516 13.3333 5.00016 13.7083 5.00016 14.1667Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_371_13013)">
        <rect width="20" height="20" fill={color} />
      </g>
    </svg>
  );
};
