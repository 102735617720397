import { type ComponentProps, type CSSProperties } from '@stitches/react';
import { type Ref, forwardRef } from 'react';

import * as S from './styles';

export interface ProgressProps
  extends Omit<ComponentProps<typeof S.Root>, 'value' | 'max'> {
  /**
   * Integer value like percentage
   * ex: 10 equals 10%
   */
  progressValue: number;
  maxValue: number;
  maxWidth?: CSSProperties['maxWidth'];
  height?: CSSProperties['height'];
}

export const Progress = forwardRef(
  (
    { progressValue, maxValue, maxWidth, height, ...props }: ProgressProps,
    ref: Ref<HTMLDivElement>
  ) => (
    <S.Root
      ref={ref}
      {...props}
      value={progressValue}
      max={maxValue}
      css={{
        maxWidth: maxWidth || 'none',
        height: height || S.defaultProgressHeight,
      }}
    >
      <S.Indicator
        css={{ transform: `translateX(-${maxValue - progressValue}%)` }}
      />
    </S.Root>
  )
);
