import * as Accordion from '@radix-ui/react-accordion';

import { styled } from '../../styles/global';

import { slideDown, slideUp } from './animations';

export const Wrapper = styled(Accordion.Root, {
  position: 'fixed',
  top: '60px',
  left: 0,

  height: '100vh',

  display: 'flex',
  flexDirection: 'column',

  backgroundColor: '$white',

  transition: 'transform 0.3s ease-in-out',

  '@medium': {
    zIndex: 98,
    width: '100vw',

    transform: 'translateX(-100%)',
    overflowY: 'auto',
  },

  variants: {
    isMobile: {
      true: {
        '@medium': {
          transform: 'translateX(0)',
        },
      },
    },
  },
});

export const Item = styled(Accordion.Item, {
  overflow: 'hidden',

  '&:first-child': {
    borderTop: '1px solid #EDF1F7',
    borderBottom: '1px solid #EDF1F7',
  },

  '&:not(:first-child)': {
    borderBottom: '1px solid #EDF1F7',
  },
});

export const ItemTrigger = styled(Accordion.Trigger, {
  all: 'unset',
  cursor: 'pointer',

  display: 'flex',
  alignItems: 'center',

  width: '240px',
  height: '52px',

  '@medium': {
    width: '100%',
  },

  padding: '14px 16px',

  '&:hover': {
    backgroundColor: '#F6F6F6',
    filter: 'none',
  },

  svg: {
    width: '20px',
    height: '20px',
  },

  '.nav-icon': {
    marginRight: '8px',
  },

  '.nav-extra-icon': {
    marginLeft: 'auto',
  },

  '.nav-title': {
    maxWidth: '166px',

    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  '.arrow-icon': {
    marginLeft: 'auto',

    transition: 'transform 300ms cubic-bezier(0.87, 0, 0.13, 1)',
  },

  '&[data-state=open] > .arrow-icon': {
    transform: 'rotate(180deg)',
  },

  color: '$black',
  fontFamily: '$base',
  fontStyle: '$normal',

  fontWeight: '$semiBold',
  fontSize: '13px',
  lineHeight: '24px',

  variants: {
    isActiveItem: {
      true: {
        color: '$primary',
        fontWeight: '$bold',
      },
    },
  },
});

export const ContentRoot = styled(Accordion.Content, {
  width: 'inherit',
  height: 'inherit',

  overflow: 'hidden',
  backgroundColor: '$white',

  '&[data-state="open"]': {
    animation: `${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
  },
  '&[data-state="closed"]': {
    animation: `${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
  },
});

export const SubItemWrapper = styled('div', {
  width: 'inherit',
  height: 'inherit',

  display: 'flex',
  flexDirection: 'column',
});

export const LinkItem = styled('a', {
  all: 'unset',
  cursor: 'pointer',

  display: 'flex',
  alignItems: 'center',

  width: '240px',
  height: '52px',

  '@medium': {
    width: '100%',
  },

  padding: '14px 16px',

  '&:hover': {
    backgroundColor: '#F6F6F6',
    filter: 'none',
  },

  svg: {
    width: '20px',
    height: '20px',
  },

  '.nav-icon': {
    marginRight: '8px',
  },

  '.nav-extra-icon': {
    marginLeft: 'auto',
  },

  '.nav-title': {
    maxWidth: '166px',

    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  '&:first-child': {
    borderTop: '1px solid #EDF1F7',
    borderBottom: '1px solid #EDF1F7',
  },

  '&:not(:first-child)': {
    borderBottom: '1px solid #EDF1F7',
  },

  color: '$black',
  fontFamily: '$base',
  fontStyle: '$normal',

  fontWeight: '$semiBold',
  fontSize: '13px',
  lineHeight: '24px',

  variants: {
    isActiveItem: {
      true: {
        color: '$primary',
        fontWeight: '$bold',
      },
    },
    isSubItem: {
      true: {
        backgroundColor: '#F6F6F6',
      },
    },
    isActiveSubItem: {
      true: {
        color: '$primary',
        fontWeight: '$bold',
      },
    },
  },
});
