import { type IconProps } from '.';

export const OutlineRoundedCloseIcon = ({
  color = '#8F9BB3',
  ...props
}: IconProps) => {
  return (
    <svg
      className="outline-rounded-close-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M9.99995 0.00219727C8.02217 0.00219727 6.08877 0.588683 4.44429 1.6875C2.7998 2.78631 1.51808 4.34808 0.76121 6.17534C0.00433601 8.0026 -0.193699 10.0133 0.192148 11.9531C0.578006 13.8929 1.53041 15.6746 2.92893 17.0732C4.32746 18.4717 6.10927 19.4242 8.04908 19.81C9.98888 20.1959 11.9995 19.9978 13.8269 19.2409C15.6541 18.4841 17.2159 17.2024 18.3146 15.5579C19.4134 13.9134 20 11.98 20 10.0021C20 8.68895 19.7413 7.3886 19.2387 6.17534C18.7362 4.96208 17.9996 3.85971 17.071 2.93111C16.1425 2.00253 15.0401 1.26594 13.8269 0.763391C12.6135 0.260856 11.3132 0.00219727 9.99995 0.00219727ZM9.99995 18.0022C8.41773 18.0022 6.87102 17.533 5.55542 16.654C4.23983 15.7748 3.21446 14.5254 2.60896 13.0636C2.00346 11.6019 1.84503 9.99326 2.15372 8.44145C2.4624 6.8896 3.22432 5.46415 4.34314 4.34533C5.46196 3.22652 6.88742 2.46459 8.43926 2.1559C9.9911 1.84723 11.5997 2.00565 13.0614 2.61115C14.5232 3.21665 15.7727 4.24203 16.6518 5.55762C17.5308 6.87321 18 8.41992 18 10.0021C18 12.1239 17.1571 14.1587 15.6569 15.659C14.1565 17.1593 12.1217 18.0022 9.99995 18.0022Z"
        fill={color}
      />
      <path
        d="M12.7099 7.29217C12.617 7.19844 12.5063 7.12404 12.3845 7.07328C12.2626 7.0225 12.132 6.99637 12 6.99637C11.868 6.99637 11.7372 7.0225 11.6154 7.07328C11.4934 7.12404 11.3829 7.19844 11.2899 7.29217L9.99992 8.59216L8.70993 7.29217C8.52163 7.10386 8.26623 6.99807 7.99993 6.99807C7.73364 6.99807 7.47824 7.10386 7.28994 7.29217C7.10164 7.48047 6.99584 7.73587 6.99584 8.00216C6.99584 8.26846 7.10164 8.52386 7.28994 8.71216L8.58993 10.0021L7.28994 11.2921C7.19621 11.3851 7.12181 11.4957 7.07105 11.6176C7.02028 11.7394 6.99414 11.8702 6.99414 12.0022C6.99414 12.1342 7.02028 12.2649 7.07105 12.3868C7.12181 12.5086 7.19621 12.6192 7.28994 12.7121C7.3829 12.8059 7.4935 12.8802 7.61536 12.9311C7.73721 12.9819 7.86792 13.0079 7.99993 13.0079C8.13194 13.0079 8.26264 12.9819 8.3845 12.9311C8.50636 12.8802 8.61697 12.8059 8.70993 12.7121L9.99992 11.4121L11.2899 12.7121C11.3829 12.8059 11.4934 12.8802 11.6154 12.9311C11.7372 12.9819 11.868 13.0079 12 13.0079C12.132 13.0079 12.2626 12.9819 12.3845 12.9311C12.5063 12.8802 12.617 12.8059 12.7099 12.7121C12.8037 12.6192 12.878 12.5086 12.9289 12.3868C12.9796 12.2649 13.0057 12.1342 13.0057 12.0022C13.0057 11.8702 12.9796 11.7394 12.9289 11.6176C12.878 11.4957 12.8037 11.3851 12.7099 11.2921L11.4099 10.0021L12.7099 8.71216C12.8037 8.6192 12.878 8.50859 12.9289 8.38673C12.9796 8.26487 13.0057 8.13417 13.0057 8.00216C13.0057 7.87015 12.9796 7.73944 12.9289 7.61758C12.878 7.49572 12.8037 7.38513 12.7099 7.29217Z"
        fill={color}
      />
    </svg>
  );
};
