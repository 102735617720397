import { type ComponentProps, type ReactNode } from 'react';

import * as S from './styles';

export interface PopoverProps extends ComponentProps<typeof S.Root> {
  activationButton: JSX.Element;
  children: ReactNode;
  side?: ComponentProps<typeof S.Content>['side'];
  align?: ComponentProps<typeof S.Content>['align'];
  sideOffset?: ComponentProps<typeof S.Content>['sideOffset'];
}

export const Popover = ({
  activationButton,
  children,
  side = 'bottom',
  align = 'center',
  sideOffset = 8,
  ...props
}: PopoverProps) => {
  return (
    <S.Root {...props}>
      <S.Trigger asChild>{activationButton}</S.Trigger>

      <S.Portal>
        <S.Content side={side} align={align} sideOffset={sideOffset}>
          {children}
        </S.Content>
      </S.Portal>
    </S.Root>
  );
};
