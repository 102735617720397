import { ptBR } from 'date-fns/locale';
import { type DayPickerRangeProps } from 'react-day-picker';

import * as S from './styles';

export type DateRangeProps = DayPickerRangeProps;

export const DateRange = (props: DateRangeProps) => {
  return (
    <S.DayPicker
      mode="range"
      numberOfMonths={2}
      pagedNavigation
      locale={ptBR}
      {...props}
    />
  );
};
