import { type IconProps } from '.';

export const CloseIcon = ({ color = '#8F9BB3', ...props }: IconProps) => {
  return (
    <svg
      className="close-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5409 17.6641L12.4103 10.5334L19.5409 3.40276C20.1981 2.74556 20.1971 1.6819 19.5409 1.02589C18.8849 0.369853 17.8212 0.368668 17.164 1.02589L10.0334 8.15655L2.90275 1.02589C2.24675 0.369853 1.18308 0.368668 0.525866 1.02589C-0.131325 1.68308 -0.130141 2.74675 0.525866 3.40276L7.65653 10.5334L0.525866 17.6641C-0.131325 18.3213 -0.130141 19.385 0.525866 20.041C1.1819 20.6969 2.24556 20.6982 2.90275 20.041L10.0334 12.9103L17.164 20.041C17.82 20.6969 18.8836 20.6982 19.5409 20.041C20.1981 19.3837 20.1971 18.3201 19.5409 17.6641Z"
        fill={color}
      />
    </svg>
  );
};
