import { type ComponentProps, type ReactNode } from 'react';

import * as S from './styles';

export interface TooltipProps extends ComponentProps<typeof S.Root> {
  children: ReactNode;
  text: string;
  href?: string;
  side?: ComponentProps<typeof S.TooltipContent>['side'];
  align?: ComponentProps<typeof S.TooltipContent>['align'];
  triggerAsChild?: boolean;
}

export const Tooltip = ({
  children,
  text,
  href,
  side = 'top',
  align = 'center',
  triggerAsChild = true,
  ...props
}: TooltipProps) => {
  return (
    <S.Provider {...props} delayDuration={0}>
      <S.Root>
        <S.Trigger asChild={triggerAsChild}>{children}</S.Trigger>

        <S.Portal>
          <S.TooltipContent side={side} align={align} sideOffset={5}>
            {text}

            {href && (
              <S.Link href={href} target="_blank" rel="noopener noreferrer">
                clique aqui
              </S.Link>
            )}
            <S.Arrow />
          </S.TooltipContent>
        </S.Portal>
      </S.Root>
    </S.Provider>
  );
};
