import { useEffect, useState } from 'react';

import { type ModalProps, defaultProps } from '.';
import information from '../../assets/icons/information.svg';
import { CloseIcon } from '../Icons';
import { Tooltip } from '../Tooltip';
import * as S from './styles';

type Info = {
  topic: string;
  value: string | number;
};

export type Product = {
  name: string;
  orderId: number;
  infos: Info[];
};

export interface ListingModalProps extends Omit<ModalProps, 'children'> {
  products: Product[];
}

export const ListingModal = ({
  heading,
  helperText,
  onClose,
  actionButton,
  width = defaultProps.width,
  maxHeight = defaultProps.maxHeight,
  showCancelButton = defaultProps.showCancelButton,
  cancelButtonTitle = defaultProps.cancelButtonTitle,
  products,
  ...props
}: ListingModalProps) => {
  const [open, setOpen] = useState(props.defaultOpen || props.open);

  const handleOpenModal = (isOpened: boolean) => {
    setOpen(isOpened);
    onClose();
  };

  useEffect(() => {
    setOpen(props.defaultOpen || props.open);
  }, [props.defaultOpen, props.open]);

  return (
    <S.Root
      {...props}
      open={open}
      onOpenChange={isOpened => handleOpenModal(isOpened)}
    >
      <S.Portal>
        <S.Overlay />

        <S.Content css={{ width, maxHeight }}>
          <S.Header>
            <S.HelperTitle>
              <S.Title>{heading}</S.Title>

              {!!helperText && (
                <Tooltip side="bottom" text={helperText}>
                  <img src={information} alt="information icon" />
                </Tooltip>
              )}
            </S.HelperTitle>

            <S.Close>
              <S.XCloseButton aria-label="Close">
                <CloseIcon color="#181921" aria-hidden />
              </S.XCloseButton>
            </S.Close>
          </S.Header>

          <S.ChildrenWrapper
            withoutActionButtons={!showCancelButton && !actionButton}
          >
            {products.length ? (
              products.map(product => (
                <S.Product key={product.name}>
                  <S.ProductNumber>{product?.orderId}</S.ProductNumber>

                  <S.ProductInfo>
                    <span className="name-info">{product.name}</span>

                    {product.infos.map(info => (
                      <span className="info" key={info.value}>
                        {info.topic}: {info.value}
                      </span>
                    ))}
                  </S.ProductInfo>
                </S.Product>
              ))
            ) : (
              <S.ProductInfo>
                <span className="no-info">Não há produtos para mostrar</span>
              </S.ProductInfo>
            )}
          </S.ChildrenWrapper>

          {(showCancelButton || actionButton) && (
            <S.ActionButtonWrapper>
              {showCancelButton && (
                <S.Close>
                  <S.CloseButton aria-label="Close">
                    {cancelButtonTitle}
                  </S.CloseButton>
                </S.Close>
              )}

              {actionButton && actionButton}
            </S.ActionButtonWrapper>
          )}
        </S.Content>
      </S.Portal>
    </S.Root>
  );
};
