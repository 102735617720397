import { type IconProps } from '.';

export const QuestionIcon = ({ color = '#8F9BB3', ...props }: IconProps) => {
  return (
    <svg
      className="question-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.2998 8.3C7.2998 6.37 8.8698 4.8 10.7998 4.8C12.7298 4.8 14.2998 6.37 14.2998 8.3C14.2998 9.88 13.2398 11.203 11.7998 11.637V12.8C11.7998 13.353 11.3528 13.8 10.7998 13.8C10.2468 13.8 9.7998 13.353 9.7998 12.8V10.8C9.7998 10.247 10.2468 9.8 10.7998 9.8C11.6268 9.8 12.2998 9.127 12.2998 8.3C12.2998 7.473 11.6268 6.8 10.7998 6.8C9.97281 6.8 9.2998 7.473 9.2998 8.3C9.2998 8.853 8.8528 9.3 8.2998 9.3C7.7468 9.3 7.2998 8.853 7.2998 8.3ZM9.7998 15.8C9.7998 15.247 10.2468 14.8 10.7998 14.8C11.3528 14.8 11.7998 15.247 11.7998 15.8C11.7998 16.353 11.3528 16.8 10.7998 16.8C10.2468 16.8 9.7998 16.353 9.7998 15.8ZM10.7998 18.8C6.3888 18.8 2.7998 15.211 2.7998 10.8C2.7998 6.389 6.3888 2.8 10.7998 2.8C15.2108 2.8 18.7998 6.389 18.7998 10.8C18.7998 15.211 15.2108 18.8 10.7998 18.8ZM10.7998 0.800003C5.2858 0.800003 0.799805 5.286 0.799805 10.8C0.799805 16.314 5.2858 20.8 10.7998 20.8C16.3138 20.8 20.7998 16.314 20.7998 10.8C20.7998 5.286 16.3138 0.800003 10.7998 0.800003Z"
        fill={color}
      />
    </svg>
  );
};
