import { styled } from '../../styles/global';

export const ContainerCritical = styled('div', {
  alignItems: 'center',
  display: 'flex',
  height: 'calc(100vh - (90px * 2))',
  flexDirection: 'column',

  '@small': {
    height: 'calc(100vh - 100px)',
  },
});

export const Title500 = styled('h1', {
  color: '$gray',
  fontFamily: '$base',
  fontSize: '100px',
  marginTop: '20px',
  textAlign: 'center',
});

export const Subtitle = styled('h2', {
  color: '$black',
  fontFamily: '$base',
  fontSize: '24px',
  textAlign: 'center',
});

export const ContainerDescription = styled('div', {
  lineHeight: '21px',

  '@isDesktop': {
    width: '350px',
  },
});

export const Description = styled('p', {
  color: '$darkGrey',
  fontFamily: '$base',
  fontSize: '14px',
  marginTop: '15px',
  textAlign: 'center',
});

export const Button = styled('button', {
  minHeight: '40px',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  padding: '12px 18px',
  marginTop: '20px',
  color: '$white',

  borderRadius: '$md',
  backgroundColor: '$primary',

  fontFamily: '$base',
  fontStyle: '$normal',
  fontWeight: '$bold',
  fontSize: '14px',
  lineHeight: '16px',

  textAlign: 'center',
});

export const ContactSupport = styled('span', {
  color: '$darkGrey',
  fontWeight: '700',
  fontSize: '14px',
  font: 'Open Sans',
  marginTop: '20px',
});
