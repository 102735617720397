import { type IconProps } from '.';

export const DoubleArrowLeftIcon = ({
  color = '#8F9BB3',
  ...props
}: IconProps) => {
  return (
    <svg
      className="double-arrow-left-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      {...props}
    >
      <path
        d="M5.00001 4.85487C4.99938 4.7452 5.02041 4.63648 5.06191 4.53495C5.10331 4.43342 5.16441 4.34107 5.24171 4.26321L8.57501 0.929869C8.65271 0.852169 8.74491 0.790539 8.84651 0.748489C8.94801 0.706439 9.05681 0.684799 9.16671 0.684799C9.27661 0.684799 9.38541 0.706439 9.48691 0.748489C9.58841 0.790539 9.68061 0.852169 9.75831 0.929869C9.83601 1.00757 9.89771 1.09981 9.93971 1.20133C9.98181 1.30285 10.0034 1.41166 10.0034 1.52154C10.0034 1.63142 9.98181 1.74023 9.93971 1.84175C9.89771 1.94327 9.83601 2.03551 9.75831 2.11321L7.00001 4.85487L9.65001 7.60491C9.73521 7.68151 9.80381 7.77491 9.85121 7.87931C9.89871 7.98361 9.92421 8.09671 9.92601 8.21131C9.92781 8.32591 9.90601 8.43971 9.86181 8.54551C9.81761 8.65131 9.75211 8.74681 9.66941 8.82611C9.58661 8.90551 9.48831 8.96681 9.38081 9.00651C9.27321 9.04611 9.15861 9.06311 9.04411 9.05641C8.92971 9.04971 8.81791 9.01951 8.71561 8.96761C8.61341 8.91571 8.52301 8.84331 8.45001 8.75491L5.23331 5.42151C5.08641 5.26914 5.00301 5.06654 5.00001 4.85487Z"
        fill={color}
      />
      <path
        d="M1.41802e-05 4.85487C-0.00062582 4.7452 0.0204043 4.63648 0.0618743 4.53495C0.103344 4.43342 0.164444 4.34107 0.241684 4.26321L3.57501 0.929869C3.65271 0.852169 3.74495 0.790539 3.84647 0.748489C3.94799 0.706439 4.05679 0.684799 4.16668 0.684799C4.27656 0.684799 4.38537 0.706439 4.48688 0.748489C4.5884 0.790539 4.68064 0.852169 4.75834 0.929869C4.83604 1.00757 4.89767 1.09981 4.93973 1.20133C4.98178 1.30285 5.0034 1.41166 5.0034 1.52154C5.0034 1.63142 4.98178 1.74023 4.93973 1.84175C4.89767 1.94327 4.83604 2.03551 4.75834 2.11321L2.00001 4.85487L4.65001 7.60491C4.73525 7.68151 4.80376 7.77491 4.85125 7.87931C4.89873 7.98361 4.92418 8.09671 4.926 8.21131C4.92781 8.32591 4.90596 8.43971 4.8618 8.54551C4.81764 8.65131 4.75213 8.74681 4.66936 8.82611C4.58659 8.90551 4.48834 8.96681 4.38076 9.00651C4.27318 9.04611 4.15859 9.06311 4.04414 9.05641C3.92969 9.04971 3.81786 9.01951 3.71563 8.96761C3.6134 8.91571 3.52297 8.84331 3.45001 8.75491L0.233344 5.42151C0.086414 5.26914 0.00299418 5.06654 1.41802e-05 4.85487Z"
        fill={color}
      />
    </svg>
  );
};
