import { type IconProps } from '.';

export const ArrowUpIcon = ({ color = '#8F9BB3', ...props }: IconProps) => {
  return (
    <svg
      className="arrow-up-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M9.99991 12.9166C9.89024 12.9173 9.78152 12.8962 9.67999 12.8548C9.57846 12.8133 9.48611 12.7522 9.40824 12.675L6.07491 9.34162C5.99721 9.26392 5.93558 9.17168 5.89353 9.07016C5.85148 8.96864 5.82983 8.85983 5.82983 8.74995C5.82983 8.64007 5.85148 8.53126 5.89353 8.42974C5.93558 8.32822 5.99721 8.23598 6.07491 8.15828C6.15261 8.08059 6.24485 8.01895 6.34637 7.9769C6.44789 7.93485 6.55669 7.91321 6.66658 7.91321C6.77646 7.91321 6.88527 7.93485 6.98679 7.9769C7.0883 8.01895 7.18054 8.08059 7.25824 8.15828L9.99991 10.9166L12.7499 8.26662C12.8266 8.18138 12.92 8.11287 13.0243 8.06538C13.1287 8.01789 13.2417 7.99244 13.3563 7.99063C13.471 7.98881 13.5847 8.01067 13.6905 8.05483C13.7963 8.09898 13.8919 8.1645 13.9712 8.24727C14.0505 8.33004 14.1119 8.42829 14.1515 8.53586C14.1911 8.64344 14.2081 8.75804 14.2014 8.87249C14.1948 8.98693 14.1645 9.09877 14.1126 9.201C14.0608 9.30323 13.9883 9.39365 13.8999 9.46662L10.5666 12.6833C10.4142 12.8302 10.2116 12.9136 9.99991 12.9166Z"
        fill={color}
      />
    </svg>
  );
};
