import { type IconProps } from '.';

export const BlockedIcon = ({ color = '#8F9BB3', ...props }: IconProps) => {
  return (
    <svg
      className="blocked-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      {...props}
    >
      <path
        d="M9.99994 0.5C8.02218 0.5 6.08878 1.08649 4.44429 2.1853C2.79981 3.28411 1.51808 4.8459 0.761207 6.67315C0.00433251 8.50041 -0.193703 10.5111 0.192156 12.4509C0.578003 14.3907 1.53041 16.1726 2.92893 17.571C4.32746 18.9695 6.10928 19.922 8.04908 20.3078C9.9889 20.6937 11.9995 20.4957 13.8269 19.7387C15.6541 18.9819 17.2159 17.7002 18.3146 16.0557C19.4134 14.4112 20 12.4778 20 10.4999C20 9.18676 19.7413 7.8864 19.2387 6.67315C18.7362 5.4599 17.9996 4.35751 17.071 3.42893C16.1425 2.50035 15.04 1.76375 13.8269 1.26121C12.6135 0.75866 11.3132 0.5 9.99994 0.5ZM18 10.4999C18.0021 12.2767 17.4069 14.0024 16.31 15.4L5.09999 4.18999C6.49753 3.09301 8.22333 2.49779 9.99994 2.5C12.1217 2.5 14.1565 3.34285 15.6568 4.84314C17.1571 6.34342 18 8.37825 18 10.4999ZM2 10.4999C1.99779 8.72333 2.59302 6.99753 3.68999 5.59999L14.9 16.81C13.5024 17.9069 11.7767 18.5021 9.99994 18.5C7.87826 18.5 5.84342 17.6571 4.34314 16.1568C2.84285 14.6565 2 12.6217 2 10.4999Z"
        fill={color}
      />
    </svg>
  );
};
