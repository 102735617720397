import { type CSSProperties } from '@stitches/react';

import { styled } from '../../styles/global';

export const Status = styled('div', {
  position: 'relative',

  width: '100%',
  minHeight: '115px',

  display: 'flex',
  padding: '30px 200px',

  borderRadius: '$md',
  border: '1px solid #E4E9F2',

  overflow: 'hidden',

  backgroundColor: '$white',
  boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.05)',

  '.progress-step': {
    marginTop: '15px',
  },

  '@large': {
    paddingX: '80px',
  },

  '@small': {
    paddingX: '48px',
  },
});

export const statusCheckWidth: CSSProperties['width'] = '32px';

export const StatusCheck = styled('span', {
  position: 'absolute',

  marginTop: '-12px',

  top: '50%',
  transform: 'translateY(-50%)',

  width: statusCheckWidth,
  height: '32px',

  display: 'flex',
  flexDirection: 'column',

  alignItems: 'center',
  justifyContent: 'center',

  borderRadius: '$lg',

  borderWidth: '1px',
  borderStyle: 'solid',

  userSelect: 'none',

  fontStyle: '$normal',
  fontFamily: '$base',
  fontWeight: '$regular',
  fontSize: '15px',
  lineHeight: '20px',

  color: '$white',

  variants: {
    isChecked: {
      false: {
        borderColor: '#E4E9F2',
        backgroundColor: '#E4E9F2',
      },
      true: {
        borderColor: '#0062DC',
        backgroundColor: '#0062DC',
      },
    },
  },
});

export const StatusCheckLabel = styled('span', {
  position: 'absolute',

  top: 'calc(50% + 33px)',
  transform: 'translateY(-50%)',

  color: '#8F9BB3',
  textAlign: 'center',

  fontStyle: '$normal',
  fontFamily: '$base',
  fontWeight: '$bold',
  fontSize: '12px',
  lineHeight: '16px',

  whiteSpace: 'nowrap',
});
