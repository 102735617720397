import { type IconProps } from '.';

export const CopyIcon = ({ color = '#8F9BB3', ...props }: IconProps) => {
  return (
    <svg
      className="copy-icon"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.6667 20H9.99999C9.11594 20 8.26809 19.6488 7.64297 19.0237C7.01784 18.3985 6.66666 17.5507 6.66666 16.6667V9.99999C6.66666 9.11594 7.01784 8.26809 7.64297 7.64297C8.26809 7.01784 9.11594 6.66666 9.99999 6.66666H16.6667C17.5507 6.66666 18.3985 7.01784 19.0237 7.64297C19.6488 8.26809 20 9.11594 20 9.99999V16.6667C20 17.5507 19.6488 18.3985 19.0237 19.0237C18.3985 19.6488 17.5507 20 16.6667 20ZM9.99999 8.88887C9.70531 8.88887 9.42269 9.00594 9.21432 9.21432C9.00594 9.42269 8.88887 9.70531 8.88887 9.99999V16.6667C8.88887 16.9613 9.00594 17.244 9.21432 17.4523C9.42269 17.6607 9.70531 17.7778 9.99999 17.7778H16.6667C16.9613 17.7778 17.244 17.6607 17.4523 17.4523C17.6607 17.244 17.7778 16.9613 17.7778 16.6667V9.99999C17.7778 9.70531 17.6607 9.42269 17.4523 9.21432C17.244 9.00594 16.9613 8.88887 16.6667 8.88887H9.99999Z"
        fill={color}
      />
      <path
        d="M7.47778 13.3333H2.96667C2.18077 13.3304 1.42787 13.0169 0.87215 12.4612C0.316433 11.9055 0.00293333 11.1526 0 10.3667V2.96667C0.00293333 2.18077 0.316433 1.42787 0.87215 0.87215C1.42787 0.316433 2.18077 0.00293333 2.96667 0H10.3667C11.1526 0.00293333 11.9055 0.316433 12.4612 0.87215C13.0169 1.42787 13.3304 2.18077 13.3333 2.96667V7.11112H11.1111V2.96667C11.1111 2.76923 11.0327 2.57988 10.8931 2.44027C10.7535 2.30065 10.5641 2.22222 10.3667 2.22222H2.96667C2.76923 2.22222 2.57988 2.30065 2.44027 2.44027C2.30065 2.57988 2.22222 2.76923 2.22222 2.96667V10.3667C2.22222 10.5641 2.30065 10.7535 2.44027 10.8931C2.57988 11.0327 2.76923 11.1111 2.96667 11.1111H7.47778V13.3333Z"
        fill={color}
      />
    </svg>
  );
};
