import { styled } from '../../styles/global';
import { shimmerEffect } from './animations';

export const Wrapper = styled('div', {
  backgroundColor: '$white',
  backgroundImage:
    'linear-gradient(to right, $white 0%, #edeef1 20%, $white 40%, $white 100%)',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '200rem 200rem',
  display: 'inline-block',
  position: 'relative',

  '-webkit-animation-duration': '1.5s',
  '-webkit-animation-fill-mode': 'forwards',
  '-webkit-animation-iteration-count': 'infinite',
  '-webkit-animation-name': shimmerEffect,
  '-webkit-animation-timing-function': 'linear',

  userSelect: 'none',
  cursor: 'wait !important',
});
