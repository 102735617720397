import { type ComponentProps, type ReactNode } from 'react';

import * as S from './styles';

export type ButtonsGroupItem = {
  value: string;
  children: ReactNode;
  disabled?: boolean;
};

export type ButtonsGroupProps = ComponentProps<typeof S.Root> & {
  items: ButtonsGroupItem[];
};

export const ButtonsGroup = ({ items, ...props }: ButtonsGroupProps) => {
  return (
    <S.Root aria-label="buttons-group" {...props}>
      {items.map(item => (
        <S.Item
          key={item.value}
          value={item.value}
          aria-label={item.value}
          disabled={!!item.disabled}
        >
          {item.children}
        </S.Item>
      ))}
    </S.Root>
  );
};
