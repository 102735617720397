import { type CSSProperties } from '@stitches/react';
import { type ElementType } from 'react';

import { Wrapper } from './styles';

interface ShimmerLoadingProps {
  as?: ElementType;
  w?: CSSProperties['width'];
  h?: CSSProperties['height'];
}

export const ShimmerLoading = ({
  as: element = 'div',
  w = undefined,
  h = undefined,
}: ShimmerLoadingProps) => {
  return (
    <Wrapper
      as={element}
      css={{
        width: w || '100%',
        height: h || '100%',
      }}
    />
  );
};
