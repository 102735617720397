import { type IconProps } from '.';

export const PlusIcon = ({ color = '#8F9BB3', ...props }: IconProps) => {
  return (
    <svg
      className="plus-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.75 9.35H11.25V1.85001C11.25 1.15876 10.69 0.600006 10 0.600006C9.31 0.600006 8.75 1.15876 8.75 1.85001V9.35H1.25C0.56 9.35 0 9.90875 0 10.6C0 11.2913 0.56 11.85 1.25 11.85H8.75V19.35C8.75 20.0413 9.31 20.6 10 20.6C10.69 20.6 11.25 20.0413 11.25 19.35V11.85H18.75C19.44 11.85 20 11.2913 20 10.6C20 9.90875 19.44 9.35 18.75 9.35Z"
        fill={color}
      />
    </svg>
  );
};
