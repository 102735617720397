import { keyframes } from '@stitches/react';

export const shimmerEffect = keyframes({
  from: {
    backgroundPosition: '100% 0',
  },
  to: {
    backgroundPosition: '-100% 0',
  },
});
