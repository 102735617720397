import * as Tooltip from '@radix-ui/react-tooltip';

import { styled } from '../../styles/global';

import {
  slideDownAndFade,
  slideLeftAndFade,
  slideRightAndFade,
  slideUpAndFade,
} from './animations';

export const { Provider, Root, Trigger, Portal } = Tooltip;

export const TooltipContent = styled(Tooltip.Content, {
  zIndex: 999,

  margin: 'auto',

  width: 'auto',
  maxWidth: '250px', // 25rem

  padding: '5px 10px', // 0.5rem 1rem

  fontFamily: '$base',
  fontStyle: '$normal',
  fontWeight: '$regular',

  fontSize: '12px', // 1.2rem
  lineHeight: '16px', // 1.6rem

  textAlign: 'center',

  color: '#343A40',

  borderRadius: '$md',
  backgroundColor: '#FFF',

  boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.15)',
  userSelect: 'none',

  animationDuration: '400ms',
  animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
  willChange: 'transform, opacity',

  '&[data-state="delayed-open"]': {
    '&[data-side="top"]': { animationName: slideDownAndFade },
    '&[data-side="right"]': { animationName: slideLeftAndFade },
    '&[data-side="bottom"]': { animationName: slideUpAndFade },
    '&[data-side="left"]': { animationName: slideRightAndFade },
  },
});

export const Link = styled('a', {
  width: 'fit-content',
  height: 'fit-content',

  color: '#36F',

  marginLeft: '2px',
});

export const Arrow = styled(Tooltip.Arrow, {
  fill: '#FFF',
});
