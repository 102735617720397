import estocaLogo from '../../assets/icons/estoca.svg';

import * as S from './styles';

export const EstocaLoading = () => {
  return (
    <S.Loading>
      <S.Spinner />
      <S.EstocaLogo>
        <S.EstocaImg src={estocaLogo} alt="" />
      </S.EstocaLogo>
    </S.Loading>
  );
};
