import { type ReactNode } from 'react';
import { Wrapper } from './styles';

interface PageContentWrapperProps {
  children: ReactNode;
}

export const PageContentWrapper = ({ children }: PageContentWrapperProps) => (
  <Wrapper>{children}</Wrapper>
);
