import { type IconProps } from '.';

export const CheckmarkSuccessIcon = (props: IconProps) => {
  return (
    <svg
      className="checkmark-success-icon"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10Z"
        fill="#3AC47D"
      />
      <path
        d="M8.87711 13.1482C8.80518 13.148 8.73404 13.1329 8.66815 13.104C8.60226 13.0752 8.543 13.033 8.49407 12.9803L5.94407 10.2676C5.84878 10.166 5.7977 10.0308 5.80215 9.89152C5.80655 9.7523 5.86611 9.62052 5.9677 9.52519C6.0693 9.42989 6.20459 9.37882 6.34381 9.38322C6.48304 9.38767 6.61481 9.44722 6.71015 9.54882L8.87185 11.8522L13.2845 7.02504C13.3293 6.9693 13.385 6.92326 13.4482 6.88982C13.5114 6.85637 13.5808 6.83619 13.6521 6.83052C13.7234 6.82489 13.7951 6.83385 13.8628 6.85689C13.9305 6.87996 13.9928 6.91663 14.0458 6.96463C14.0988 7.01263 14.1414 7.07096 14.1711 7.13604C14.2007 7.20111 14.2168 7.27159 14.2182 7.34308C14.2196 7.41459 14.2064 7.48563 14.1794 7.55185C14.1524 7.61804 14.1121 7.67804 14.0611 7.72815L9.26537 12.975C9.21693 13.0287 9.15789 13.0719 9.09196 13.1017C9.02607 13.1314 8.9547 13.1473 8.88237 13.1482H8.87711Z"
        fill="#ffffff"
      />
    </svg>
  );
};
