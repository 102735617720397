import { type ReactNode } from 'react';

import { theme } from '../../styles/theme';
import { InfoIcon } from '../Icons';
import { Tooltip } from '../Tooltip';

import * as S from './styles';

type Label = {
  text: string;
  childElementId: string;
};

export interface FieldLabelProps {
  label: Label;
  requiredField?: boolean;
  error?: boolean;
  helperText?: string;
  children: ReactNode;
}

export const FieldLabel = ({
  label,
  requiredField = false,
  error = false,
  helperText = undefined,
  children,
}: FieldLabelProps) => {
  return (
    <S.Wrapper className="field-label" error={error}>
      <S.Label htmlFor={label.childElementId}>
        {label.text}
        {requiredField ? ' *' : ''}
        {helperText && (
          <Tooltip text={helperText}>
            <span>
              <InfoIcon color={theme.colors.darkGrey} aria-hidden />
            </span>
          </Tooltip>
        )}
      </S.Label>

      {children}
    </S.Wrapper>
  );
};
