import * as Dialog from '@radix-ui/react-dialog';

import { styled } from '../../styles/global';

import { contentShow, overlayShow } from './animations';

export const { Root, Portal } = Dialog;

export const Overlay = styled(Dialog.Overlay, {
  zIndex: 99,

  backgroundColor: 'rgba(0, 0, 0, 0.4)',
  position: 'fixed',
  inset: 0,
  animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
});

export const Content = styled(Dialog.Content, {
  zIndex: 999,

  position: 'fixed',

  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  display: 'flex',
  flexDirection: 'column',

  padding: '20px',

  borderRadius: '$md',
  border: '1px solid #E4E9F2',
  backgroundColor: '$white',

  boxShadow:
    'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',

  animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,

  '&:focus': {
    outline: 'none',
  },

  '@medium': {
    width: '90% !important',
    margin: '0 auto',
  },
});

export const Header = styled('header', {
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',

  borderBottom: '0.8px solid #EDF1F7',
  paddingBottom: '20px',
});

export const HelperTitle = styled('div', {
  display: 'flex',
  gap: '8.2px',
});

export const Title = styled(Dialog.Title, {
  all: 'unset',

  fontFamily: '$base',
  fontStyle: '$normal',

  fontSize: '28px',
  fontWeight: '$semiBold',
  lineHeight: '24px',

  color: '$black',
});

export const ChildrenWrapper = styled('main', {
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '20px',

  overflowY: 'auto',

  '& > *': {
    margin: 0,
    padding: 0,
    boxSizing: 'border-box',
  },

  variants: {
    withoutActionButtons: {
      false: {
        paddingBottom: '20px',
      },
    },
  },
});

export const Product = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '16px',

  '&:not(:first-child)': {
    paddingTop: '16px',
  },

  '&:not(:last-child)': {
    paddingBottom: '16px',
    borderBottom: '1px solid #DADADA',
  },
});

export const ProductNumber = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  padding: '8px 16px',

  borderRadius: '$lg',
  background: '#0062DC',

  color: '$white',

  fontFamily: '$base',
  fontStyle: '$normal',

  fontWeight: '$bold',
  fontSize: '14px',
  lineHeight: '16px',

  textAlign: 'center',
  textTransform: 'uppercase',
});

export const ProductInfo = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',

  '.name-info, .info, .no-info': {
    color: '$black',

    fontFamily: '$base',
    fontStyle: '$normal',

    fontWeight: '$regular',
    fontSize: '16px',
    lineHeight: '25px',
  },

  '.no-info': {
    margin: 'auto',
  },

  '.name-info': {
    fontWeight: '$bold',
  },
});

export const ActionButtonWrapper = styled('footer', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',

  gap: '20px',
  paddingTop: '20px',

  borderTop: '0.8px solid #EDF1F7',
});

export const Close = styled(Dialog.Close, {
  all: 'unset',
});

export const XCloseButton = styled('button', {
  all: 'unset',
  cursor: 'pointer',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  width: 'fit-content',
  height: 'fit-content',

  svg: {
    width: '18px',
    height: '18px',
  },

  color: '$black',

  transition: 'all .2s ease-in-out',

  '&:hover': {
    filter: 'brightness(0.95)',
  },

  '&:disabled, &[disabled]': {
    cursor: 'not-allowed',
  },

  '&:focus': {
    boxShadow: '0 0 0 2px #181921',
  },
});

export const CloseButton = styled('button', {
  all: 'unset',

  cursor: 'pointer',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  width: 'fit-content',
  height: 'fit-content',

  padding: '12px 18px',
  borderRadius: '$md',

  border: '1px solid #E5EBF5',
  backgroundColor: '#F6F6F6',

  color: '$black',

  fontFamily: '$base',
  fontStyle: '$normal',

  fontWeight: '$bold',
  fontSize: '14px',
  lineHeight: '16px',

  textAlign: 'center',
  textTransform: 'uppercase',

  transition: 'all .2s ease-in-out',

  '&:hover': {
    filter: 'brightness(0.95)',
  },

  '&:disabled, &[disabled]': {
    cursor: 'not-allowed',
  },

  '&:focus': {
    boxShadow: '0 0 0 2px #181921',
  },
});
