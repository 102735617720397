import * as MyProgress from '@radix-ui/react-progress';
import { type CSSProperties } from '@stitches/react';

import { styled } from '../../styles/global';

export const defaultProgressHeight: CSSProperties['height'] = '2px';

export const Root = styled(MyProgress.Root, {
  position: 'relative',

  width: '100%',
  height: defaultProgressHeight,

  margin: 'auto',

  overflow: 'hidden',

  backgroundColor: '#E5EBF5',
  borderRadius: '$full',

  // Fix overflow clipping in Safari: https://gist.github.com/domske/b66047671c780a238b51c51ffde8d3a0
  transform: 'translateZ(0)',
});

export const Indicator = styled('div', {
  position: 'absolute',
  inset: 0,

  backgroundColor: '#0062DC',
  width: '100%',
  height: '100%',
  transition: 'transform 660ms cubic-bezier(0.65, 0, 0.35, 1)',
});
