import { type IconProps } from '.';

export const FileIcon = ({ color = '#8F9BB3', ...props }: IconProps) => {
  return (
    <svg
      className="file-icon"
      width="18"
      height="24"
      viewBox="0 0 18 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.7391 5.34738L12.8415 0.27046C12.6741 0.0969201 12.4479 0 12.2119 0H2.18098C1.197 0 0.400024 0.82616 0.400024 1.84616V22.1538C0.400024 23.1738 1.197 24 2.18098 24H16.2191C17.203 24 18 23.1738 18 22.1538V6C18 5.75538 17.9065 5.52092 17.7391 5.34738ZM12.6572 6.4615C12.1656 6.4615 11.7667 6.048 11.7667 5.53846V1.75754L16.3046 6.4615H12.6572Z"
        fill={color}
      />
    </svg>
  );
};
