import { type IconProps } from '.';

export const TrashIcon = ({ color = '#8F9BB3', ...props }: IconProps) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9999 17C15.9999 17.551 15.5519 18 14.9999 18H4.99994C4.44794 18 3.99994 17.551 3.99994 17V6H15.9999V17ZM7.99994 2.328C7.99994 2.173 8.2139 2 8.4999 2H11.4999C11.7859 2 11.9999 2.173 11.9999 2.328V4H7.99994V2.328ZM18.9999 4H17.9999H13.9999V2.328C13.9999 1.044 12.8789 0 11.4999 0H8.4999C7.12094 0 5.99994 1.044 5.99994 2.328V4H1.99994H0.999939C0.449939 4 -6.10352e-05 4.45 -6.10352e-05 5C-6.10352e-05 5.55 0.449939 6 0.999939 6H1.99994V17C1.99994 18.654 3.34594 20 4.99994 20H14.9999C16.6539 20 17.9999 18.654 17.9999 17V6H18.9999C19.5499 6 19.9999 5.55 19.9999 5C19.9999 4.45 19.5499 4 18.9999 4Z"
        fill={color}
      />
    </svg>
  );
};
