import { forwardRef, type ComponentProps, type Ref } from 'react';

import { ArrowUpIcon } from '../Icons';

import * as S from './styles';

export type AccordionTriggerProps = ComponentProps<typeof S.Trigger>;

export const AccordionTrigger = forwardRef(
  (
    { children, ...props }: AccordionTriggerProps,
    ref: Ref<HTMLButtonElement>
  ) => (
    <S.Header errorVariant={!!props.errorVariant}>
      <S.Trigger {...props} ref={ref}>
        {children}

        <ArrowUpIcon color="#181921" className="arrow-icon" aria-hidden />
      </S.Trigger>
    </S.Header>
  )
);
