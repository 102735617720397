import { keyframes } from '../../styles/global';

export const scaleUpCenter = keyframes({
  from: {
    transform: 'scale(0)',
  },
  to: {
    transform: 'scale(1)',
  },
});

export const scaleDownCenter = keyframes({
  from: {
    transform: 'scale(1)',
  },
  to: {
    transform: 'scale(0)',
  },
});
