import { type ElementType, type SVGProps } from 'react';

import * as S from './styles';

interface IconProps extends SVGProps<SVGSVGElement> {
  color?: SVGProps<SVGElement>['fill'];
}

interface GreetingsProps {
  title: string;
  description: string;
  icon: string | ElementType<IconProps>;
}

export const Greetings = ({
  title,
  description,
  icon: Icon,
}: GreetingsProps) => {
  return (
    <S.Wrapper>
      <S.IconWrapper>
        {typeof Icon === 'string' ? (
          <img src={Icon} alt={`${title} icon`} />
        ) : (
          <Icon color="#ffffff" aria-hidden />
        )}
      </S.IconWrapper>

      <S.InfoWrapper>
        <h1 className="title">{title}</h1>
        <p className="description">{description}</p>
      </S.InfoWrapper>
    </S.Wrapper>
  );
};
