import { type ComponentProps, type ElementType } from 'react';
import { type CSSProperties } from '@stitches/react';
import {
  OutlineRoundedCheckmarkIcon,
  OutlineRoundedCloseIcon,
  InfoIcon,
  WarningIcon,
} from '../Icons';

import * as S from './styles';

export type AlertVariant =
  | 'info'
  | 'warning'
  | 'success'
  | 'error'
  | 'abstract';

type CustomIcon = {
  element: ElementType;
  color?: CSSProperties['color'];
};

export type AlertProps = ComponentProps<typeof S.Wrapper> & {
  variant: AlertVariant;
  customIcon?: CustomIcon;
  title: string;
  description?: string;
};

const variantIcon: {
  [key in AlertVariant]: ElementType;
} = {
  info: InfoIcon,
  warning: WarningIcon,
  success: OutlineRoundedCheckmarkIcon,
  error: OutlineRoundedCloseIcon,
  abstract: InfoIcon,
};

export const Alert = ({
  variant,
  customIcon,
  title,
  description,
  ...props
}: AlertProps) => {
  const Icon = customIcon?.element || variantIcon[variant];

  return (
    <S.Wrapper variant={variant} {...props}>
      <Icon
        className="icon"
        color={customIcon?.color || S.variantColors[variant]}
        aria-hidden
      />

      <S.Column>
        <S.Title>{title}</S.Title>
        {description && <S.Description>{description}</S.Description>}
      </S.Column>
    </S.Wrapper>
  );
};
