import { styled } from '../../styles/global';

export const ContainerLoading = styled('div', {
  alignItems: 'center',
  display: 'flex',
  height: 'calc(100vh - (90px * 2))',
  flexDirection: 'column',
  justifyContent: 'center',

  '@small': {
    height: 'calc(100vh - 100px)',
  },
});

export const TitleLoading = styled('h1', {
  color: '$black',
  fontSize: '18px',
  marginTop: '20px',
  textAlign: 'center',
});
