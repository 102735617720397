import { type IconProps } from '.';

export const PhoneIcon = ({ color = '#8F9BB3', ...props }: IconProps) => {
  return (
    <svg
      className="phone-icon"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.5999 13.6667C7.87811 13.6631 5.2688 12.5803 3.34419 10.6557C1.41958 8.73112 0.336782 6.1218 0.333252 3.39999C0.333252 2.58666 0.656352 1.80665 1.23146 1.23153C1.80657 0.656418 2.58659 0.333328 3.39992 0.333328C3.57215 0.332018 3.74408 0.347648 3.91325 0.379988C4.07679 0.404198 4.23756 0.444388 4.39325 0.499988C4.50275 0.538408 4.60032 0.604748 4.67632 0.692438C4.75233 0.780138 4.80412 0.886148 4.82659 0.999988L5.73992 5C5.76454 5.10857 5.76157 5.22158 5.73129 5.32872C5.70102 5.43585 5.64439 5.5337 5.56659 5.61333C5.47992 5.70666 5.47325 5.71333 4.65325 6.14C5.30991 7.58057 6.46205 8.73743 7.89992 9.4C8.33325 8.57333 8.33992 8.56666 8.43325 8.48C8.51288 8.40219 8.61073 8.34556 8.71786 8.31529C8.825 8.28501 8.93801 8.28204 9.0466 8.30666L13.0466 9.22C13.1568 9.2456 13.2587 9.2988 13.3426 9.3746C13.4266 9.4504 13.4899 9.5463 13.5266 9.6533C13.5829 9.8116 13.6252 9.9744 13.6533 10.14C13.6801 10.3075 13.6935 10.477 13.6933 10.6467C13.681 11.4565 13.3488 12.2286 12.7692 12.7944C12.1897 13.3602 11.4098 13.6738 10.5999 13.6667ZM3.39992 1.66666C2.94075 1.66842 2.50089 1.8516 2.17621 2.17628C1.85152 2.50097 1.66834 2.94083 1.66659 3.39999C1.66835 5.76872 2.61011 8.03992 4.28505 9.7149C5.95999 11.3898 8.23119 12.3316 10.5999 12.3333C11.0591 12.3316 11.4989 12.1484 11.8236 11.8237C12.1483 11.499 12.3315 11.0592 12.3333 10.6V10.38L9.2399 9.6667L9.0466 10.0333C8.74658 10.6133 8.52659 11.0333 7.96659 10.8067C6.86184 10.4112 5.85905 9.7747 5.03108 8.94325C4.2031 8.11181 3.57073 7.10638 3.17992 6C2.93992 5.48 3.39325 5.24 3.96659 4.93999L4.33325 4.76L3.61992 1.66666H3.39992Z"
        fill={color}
      />
    </svg>
  );
};
