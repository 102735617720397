import { ptBR } from 'date-fns/locale';
import { type DayPickerProps } from 'react-day-picker';

import * as S from './styles';

export type CalendarProps = DayPickerProps;

export const Calendar = (props: CalendarProps) => {
  return <S.DayPicker locale={ptBR} {...props} />;
};
