import { styled } from '../../styles/global';
import { DayPicker as CalendarStyles } from '../Calendar/styles';

export const DayPicker = styled(CalendarStyles, {
  '--rdp-cell-size': '36px !important',

  padding: '0',

  '.rdp-months': {
    height: '100%',
  },

  '.rdp-month': {
    margin: 0,
    paddingX: '16px',
  },

  '.rdp-head_cell, .rdp-cell, .rdp-button': {
    fontWeight: '$regular',
  },

  '.rdp-cell, .rdp-button': {
    borderRadius: '$sm',

    '.rdp-day_range_end.rdp-day_range_start': {
      borderRadius: '$sm',
    },

    '.rdp-day_range_start, .rdp-day_range_end': {
      backgroundColor: '$primary',
      color: '$white',
      fontWeight: '$semiBold',
    },

    '.rdp-day_range_middle': {
      borderRadius: 0,
    },
  },

  '.rdp-day_selected': {
    backgroundColor: '#E5EBF5',
  },

  '.rdp-day_selected, .rdp-day_selected:focus-visible, .rdp-day_selected:hover':
    {
      border: 'none',
    },
});
