import { type ComponentProps } from 'react';

import { type ProgressStepValue } from '../ProgressStep';

import * as S from './styles';

import { type OrderStepProgressStepsValue } from '.';

const separatorWidth = 40;
export const rightAndLeftSeparatorWidth = `${separatorWidth * 2}px`;

interface StatusCheckProps extends ComponentProps<typeof S.StatusCheck> {
  progressWidth: string | undefined;
  index: number;
  label: ProgressStepValue['label'];
  /**
   * Integer value like percentage
   * ex: 10 equals 10%
   */
  isActiveStepChecked: boolean;
  activeStatus: string;
  isUncompletedStep: OrderStepProgressStepsValue['isUncompleted'];
  hasActionClick: boolean;
  relatedStatuses: OrderStepProgressStepsValue['relatedStatuses'];
  description: OrderStepProgressStepsValue['description'];
  onStepClick: OrderStepProgressStepsValue['onStepClick'];
}

export const StatusCheck = ({
  progressWidth,
  index,
  label,
  isActiveStepChecked,
  isUncompletedStep,
  hasActionClick,
  activeStatus,
  relatedStatuses,
  description,
  onStepClick,
}: StatusCheckProps) => {
  if (!progressWidth) return null;

  const isFirstItem = index === 1;

  const marginLeft = isFirstItem
    ? `${separatorWidth}px`
    : `calc((${rightAndLeftSeparatorWidth} * ${index}) - ${separatorWidth}px)`;

  return (
    <S.StatusCheck
      css={{ marginLeft }}
      isActiveStepChecked={isActiveStepChecked}
      isUncompletedStep={isUncompletedStep}
      isActiveStatus={relatedStatuses.includes(activeStatus)}
      onClick={hasActionClick ? onStepClick : undefined}
      hasActionClick={hasActionClick}
    >
      <S.StatusCheckLabel
        className="label"
        data-date={isActiveStepChecked && description}
      >
        {label}
      </S.StatusCheckLabel>
    </S.StatusCheck>
  );
};
