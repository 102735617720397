import { type IconProps } from '.';

export const CarIcon = ({ color = '#8F9BB3', ...props }: IconProps) => {
  return (
    <svg
      className="car-icon"
      width="18"
      height="16"
      fill="none"
      viewBox="0 0 18 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill={color}
        d="M17.64 7.297L13.5 4.013V1.775a1.681 1.681 0 00-.442-1.22A1.728 1.728 0 0011.872 0H1.612C1.163.027.74.23.439.562a1.682 1.682 0 00-.436 1.213v8.878c-.008.352.09.7.282.996.193.298.47.532.798.673a2.63 2.63 0 00.242 2.468c.24.362.566.66.949.87a2.728 2.728 0 003.5-.776 2.643 2.643 0 00.348-2.456h5.724a2.628 2.628 0 00.327 2.437c.249.35.58.637.964.834a2.73 2.73 0 002.493 0c.384-.197.715-.483.964-.834a2.642 2.642 0 00.327-2.437h.18a.906.906 0 00.636-.26.882.882 0 00.264-.627V7.99a.878.878 0 00-.36-.693zM16.2 8.416v2.237h-2.7V6.285l2.7 2.13zm-11.698 4.9a.88.88 0 01-.152.493.91.91 0 01-1.385.135.884.884 0 01-.195-.968.89.89 0 01.332-.398.909.909 0 011.136.11.882.882 0 01.264.628zM9 10.653h-7.2V1.775h9.9v8.878H9zm6.3 2.663a.88.88 0 01-.152.493.91.91 0 01-1.385.135.884.884 0 01-.195-.968.89.89 0 01.331-.398.909.909 0 011.137.11.882.882 0 01.263.628z"
      />
    </svg>
  );
};
