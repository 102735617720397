import { forwardRef, type ComponentProps, type Ref } from 'react';

import { ArrowUpIcon } from '../Icons/ArrowUpIcon';

import * as S from './styles';

export const ItemTrigger = forwardRef(
  (
    { children, ...props }: ComponentProps<typeof S.ItemTrigger>,
    ref: Ref<HTMLButtonElement>
  ) => (
    <S.ItemTrigger {...props} ref={ref}>
      {children}

      <ArrowUpIcon className="arrow-icon" aria-hidden />
    </S.ItemTrigger>
  )
);

export const ItemContent = forwardRef(
  (
    { children, ...props }: ComponentProps<typeof S.SubItemWrapper>,
    ref: Ref<HTMLDivElement>
  ) => (
    <S.ContentRoot {...props} ref={ref}>
      <S.SubItemWrapper>{children}</S.SubItemWrapper>
    </S.ContentRoot>
  )
);
