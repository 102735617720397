import { type IconProps } from '.';

export const CubeIcon = ({ color = '#8F9BB3', ...props }: IconProps) => {
  return (
    <svg
      className="cube-icon"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill={color}
        d="M19.622 4.721c0-.077-.11-.155-.166-.233l-.1-.111a2.778 2.778 0 00-.956-.755L11.289.29a3.001 3.001 0 00-2.511 0L1.667 3.622a2.889 2.889 0 00-.956.755l-.133.056a1.11 1.11 0 00-.167.222A2.653 2.653 0 000 6.054v7.84a2.765 2.765 0 001.622 2.51l7.111 3.333a3 3 0 002.523 0l7.11-3.332A2.767 2.767 0 0020 13.917V6.054a2.653 2.653 0 00-.378-1.333zM9.678 2.278a.811.811 0 01.644 0l5.922 2.754L10 7.931 3.756 5.032l5.922-2.754zm-7.122 12.15a.523.523 0 01-.334-.478V6.766L8.89 9.863v7.464l-6.333-2.899zm14.877 0l-6.322 2.899V9.863l6.667-3.098v7.152a.533.533 0 01-.345.511z"
      />
    </svg>
  );
};
