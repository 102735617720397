import { type IconProps } from '.';

export const VerticalMenuDotIcon = ({
  color = '#8F9BB3',
  ...props
}: IconProps) => {
  return (
    <svg
      className="vertical-menu-dot-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="4"
      height="18"
      viewBox="0 0 4 18"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 4C3.104 4 4 3.104 4 2C4 0.896 3.104 0 2 0C0.896 0 0 0.896 0 2C0 3.104 0.896 4 2 4ZM2 7C0.896 7 0 7.896 0 9C0 10.104 0.896 11 2 11C3.104 11 4 10.104 4 9C4 7.896 3.104 7 2 7ZM0 16C0 14.896 0.896 14 2 14C3.104 14 4 14.896 4 16C4 17.104 3.104 18 2 18C0.896 18 0 17.104 0 16Z"
        fill={color}
      />
    </svg>
  );
};
