import { type IconProps } from '.';

export const ArchiveIcon = ({ color = '#8F9BB3', ...props }: IconProps) => {
  return (
    <svg
      className="archive-icon"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.22183 11.6667H11.7785C12.1752 11.6667 12.5002 11.3417 12.5002 10.945V10.7217C12.5002 10.325 12.1752 10 11.7785 10H8.22183C7.82433 10 7.50017 10.325 7.50017 10.7217V10.945C7.50017 11.3417 7.82433 11.6667 8.22183 11.6667ZM15 15C15 15.4592 14.6267 15.8333 14.1667 15.8333H5.83333C5.37417 15.8333 5 15.4592 5 15V7.5H15V15ZM5 4.16667H15C15.46 4.16667 15.8333 4.54083 15.8333 5C15.8333 5.45917 15.46 5.83333 15 5.83333H5C4.54083 5.83333 4.16667 5.45917 4.16667 5C4.16667 4.54083 4.54083 4.16667 5 4.16667ZM17.5 5C17.5 3.62167 16.3783 2.5 15 2.5H5C3.62167 2.5 2.5 3.62167 2.5 5C2.5 5.73583 2.82583 6.39167 3.33333 6.85V15C3.33333 16.3783 4.455 17.5 5.83333 17.5H14.1667C15.545 17.5 16.6667 16.3783 16.6667 15V6.85C17.1742 6.39167 17.5 5.73583 17.5 5Z"
        fill={color}
      />
      <mask
        id="mask0_371_13038"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="2"
        y="2"
        width="16"
        height="16"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.22183 11.6667H11.7785C12.1752 11.6667 12.5002 11.3417 12.5002 10.945V10.7217C12.5002 10.325 12.1752 10 11.7785 10H8.22183C7.82433 10 7.50017 10.325 7.50017 10.7217V10.945C7.50017 11.3417 7.82433 11.6667 8.22183 11.6667ZM15 15C15 15.4592 14.6267 15.8333 14.1667 15.8333H5.83333C5.37417 15.8333 5 15.4592 5 15V7.5H15V15ZM5 4.16667H15C15.46 4.16667 15.8333 4.54083 15.8333 5C15.8333 5.45917 15.46 5.83333 15 5.83333H5C4.54083 5.83333 4.16667 5.45917 4.16667 5C4.16667 4.54083 4.54083 4.16667 5 4.16667ZM17.5 5C17.5 3.62167 16.3783 2.5 15 2.5H5C3.62167 2.5 2.5 3.62167 2.5 5C2.5 5.73583 2.82583 6.39167 3.33333 6.85V15C3.33333 16.3783 4.455 17.5 5.83333 17.5H14.1667C15.545 17.5 16.6667 16.3783 16.6667 15V6.85C17.1742 6.39167 17.5 5.73583 17.5 5Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_371_13038)">
        <rect width="20" height="20" fill={color} />
      </g>
    </svg>
  );
};
