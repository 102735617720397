import {
  ArchiveIcon,
  BarChartIcon,
  DownloadIcon,
  HomeIcon,
  UploadIcon,
} from '../components/Icons';
import { type NavItem } from '../components/Navbar';

export const NAV_ITEMS: NavItem[] = [
  {
    title: 'Dashboard',
    icon: HomeIcon,
    href: '/',
  },
  {
    title: 'Relatórios',
    icon: BarChartIcon,
    subItems: [
      {
        title: 'Relatórios',
        href: '/analytics',
        isNew: true,
      },
      {
        title: 'Central de tickets',
        href: '/analytics/tickets',
      },
    ],
  },
  {
    title: 'Armazenagem',
    icon: DownloadIcon,
    subItems: [
      {
        title: 'Novo envio',
        href: '/inbound/new',
      },
      {
        title: 'Meus envios',
        href: '/inbound',
        isNew: true,
      },
      {
        title: 'Revisão do envio',
        href: '/send-invoice',
        isHiddenSubject: true,
      },
      {
        title: 'Revisão do envio',
        href: '/review',
        isHiddenSubject: true,
      },
      {
        title: 'Continuação do envio',
        href: '/continue',
        isHiddenSubject: true,
      },
    ],
  },
  {
    title: 'Estoque',
    icon: ArchiveIcon,
    subItems: [
      {
        title: 'Itens armazenados',
        href: '/inventory/performance',
        isNew: true,
      },
      {
        title: 'Controle de lote e validade',
        href: '/inventory/control',
        isNew: true,
      },
    ],
  },
  {
    title: 'Vendas',
    icon: UploadIcon,
    subItems: [
      {
        title: 'Novo pedido',
        href: '/outbound/new',
        isHiddenSubject: true,
      },
      {
        title: 'Pedidos',
        href: '/outbound',
      },
      {
        title: 'Linha do tempo do pedido',
        href: '/outbound/order/timeline',
        isHiddenSubject: true,
      },
      {
        title: 'Pedidos sem estoque',
        href: '/outbound/out-of-stock',
        isNew: true,
      },
      {
        title: 'Pedidos deletados',
        href: '/outbound/deleted',
        isNew: true,
      },
      {
        title: 'Pedidos com erros',
        href: '/outbound/errors',
        isNew: true,
      },
    ],
  },
];
