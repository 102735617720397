import { type ElementRef, useRef } from 'react';

import {
  ProgressStep,
  useProgressWidth,
  type ProgressStepValue,
} from '../ProgressStep';
import { ShimmerLoading } from '../ShimmerLoading';

import { StatusCheck } from './StatusCheck';
import * as S from './styles';

const Loading = () => <ShimmerLoading as={S.Status} />;

interface StepStatusProps {
  isLoading: boolean;
  activeStep: number;
  progressStepValues: ProgressStepValue[];
}

export const StepStatus = ({
  isLoading,
  activeStep,
  progressStepValues,
}: StepStatusProps) => {
  const progressRef = useRef<ElementRef<'div'>>(null);
  const { progressWidth } = useProgressWidth({ progressRef, isLoading });

  if (isLoading || !progressStepValues.length) return <Loading />;

  const lengthWithoutFirstItem = progressStepValues.length - 1;
  const progressWidthParsed = progressWidth
    ? Number(progressWidth.split('px')[0])
    : 0;
  const leftMarginForTheMiddleItem =
    progressWidthParsed / lengthWithoutFirstItem;

  return (
    <S.Status>
      <ProgressStep
        className="progress-step"
        ref={progressRef}
        activeStep={activeStep}
        values={progressStepValues}
      />

      {progressStepValues.map((step, index) => (
        <StatusCheck
          key={step.label}
          progressWidth={progressWidth}
          index={index + 1}
          label={step.label}
          progressValue={step.progressValue}
          isChecked={index + 1 <= activeStep}
          isFirstItem={step.label === progressStepValues[0].label}
          leftMarginForTheMiddleItem={leftMarginForTheMiddleItem}
        />
      ))}
    </S.Status>
  );
};
