import { type ChangeEvent } from 'react';

import * as S from './styles';

const rowsPerPageOptions: number[] = [10, 25, 50, 100];

const siblingsPagesSeparator = 2;

// ex: generatePagesArray(1, 5) => [2, 3, 4, 5]
const generatePagesArray = (from: number, to: number) => {
  return [...new Array(to - from)]
    .map((_, index) => from + index + 1)
    .filter(page => page > 0);
};

interface PaginationProps {
  page: number;
  rowsPerPage: number;
  pageLength: number;
  total: number;
  onHandleSelectPage: (page: number, rowsPerPage: number) => Promise<void>;
}

export const Pagination = ({
  page,
  rowsPerPage,
  pageLength,
  total,
  onHandleSelectPage,
}: PaginationProps) => {
  const lastPage = Math.ceil(total / rowsPerPage);

  const previousPages =
    page > 1
      ? generatePagesArray(page - 1 - siblingsPagesSeparator, page - 1)
      : [];

  const nextPages =
    page < lastPage
      ? generatePagesArray(
          page,
          Math.min(page + siblingsPagesSeparator, lastPage)
        )
      : [];

  const handleSelectRowsPerPageOption = ({
    target: { value },
  }: ChangeEvent<HTMLSelectElement>) => {
    const rowsPerPage = Number(value);
    onHandleSelectPage(1, rowsPerPage);
  };

  const handleToggleFirstPage = () => onHandleSelectPage(1, rowsPerPage);

  const handleToggleLastPage = () => onHandleSelectPage(lastPage, rowsPerPage);

  const handleSelectPage = (page: number) =>
    onHandleSelectPage(page, rowsPerPage);

  return (
    <S.Wrapper>
      <S.Description>
        Linhas por página:
        <S.RowsPerPageSelect
          value={rowsPerPage}
          onChange={handleSelectRowsPerPageOption}
        >
          {rowsPerPageOptions.map(option => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </S.RowsPerPageSelect>
      </S.Description>

      <S.Description>
        Mostrando {pageLength || 0} de {total || 0} resultados
      </S.Description>

      <S.ButtonsWrapper>
        {page > siblingsPagesSeparator + 1 && (
          <>
            <S.PageButton
              type="button"
              title="Voltar para a primeira página"
              onClick={handleToggleFirstPage}
            >
              1
            </S.PageButton>

            {page > siblingsPagesSeparator + 2 && (
              <S.PageButton type="button" separator>
                ...
              </S.PageButton>
            )}
          </>
        )}

        {previousPages.length > 0 &&
          previousPages.map(page => (
            <S.PageButton
              key={page}
              type="button"
              title={`Voltar para a página ${page}`}
              onClick={() => handleSelectPage(page)}
            >
              {page}
            </S.PageButton>
          ))}

        <S.PageButton type="button" active>
          {page}
        </S.PageButton>

        {nextPages.length > 0 &&
          nextPages.map(page => (
            <S.PageButton
              key={page}
              type="button"
              title={`Ir para a página ${page}`}
              onClick={() => handleSelectPage(page)}
            >
              {page}
            </S.PageButton>
          ))}

        {page + siblingsPagesSeparator < lastPage && (
          <>
            {page + 1 + siblingsPagesSeparator < lastPage && (
              <S.PageButton type="button" separator>
                ...
              </S.PageButton>
            )}

            <S.PageButton
              type="button"
              title="Ir para a última página"
              onClick={handleToggleLastPage}
            >
              {lastPage}
            </S.PageButton>
          </>
        )}
      </S.ButtonsWrapper>
    </S.Wrapper>
  );
};
