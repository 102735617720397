import { type CSSProperties } from '@stitches/react';
import {
  useEffect,
  useState,
  type ComponentProps,
  type ReactNode,
} from 'react';

import information from '../../assets/icons/information.svg';
import { CloseIcon } from '../Icons';
import { Tooltip } from '../Tooltip';
import * as S from './styles';

export interface ModalProps extends ComponentProps<typeof S.Root> {
  heading: string | ReactNode;
  helperText?: string;
  onClose: () => void;
  actionButton?: JSX.Element;
  showCancelButton?: boolean;
  cancelButtonTitle?: string;
  width?: CSSProperties['width'];
  maxHeight?: CSSProperties['maxHeight'];
  children: ReactNode;
}

export const defaultProps: Partial<ModalProps> = {
  width: '535px',
  maxHeight: '510px',
  showCancelButton: true,
  cancelButtonTitle: 'Cancelar',
};

export const Modal = ({
  heading,
  helperText,
  onClose,
  actionButton,
  width = defaultProps.width,
  maxHeight = defaultProps.maxHeight,
  showCancelButton = defaultProps.showCancelButton,
  cancelButtonTitle = defaultProps.cancelButtonTitle,
  children,
  ...props
}: ModalProps) => {
  const [open, setOpen] = useState(props.defaultOpen || props.open);

  const handleOpenModal = (isOpened: boolean) => {
    setOpen(isOpened);
    onClose();
  };

  useEffect(() => {
    setOpen(props.defaultOpen || props.open);
  }, [props.defaultOpen, props.open]);

  return (
    <S.Root
      {...props}
      open={open}
      onOpenChange={isOpened => handleOpenModal(isOpened)}
    >
      <S.Portal>
        <S.Overlay />

        <S.Content css={{ width, maxHeight }}>
          <S.Header>
            <S.HelperTitle>
              <S.Title>{heading}</S.Title>

              {!!helperText && (
                <Tooltip side="bottom" text={helperText}>
                  <img src={information} alt="information icon" />
                </Tooltip>
              )}
            </S.HelperTitle>

            <S.Close>
              <S.XCloseButton aria-label="Close">
                <CloseIcon color="#181921" aria-hidden />
              </S.XCloseButton>
            </S.Close>
          </S.Header>

          <S.ChildrenWrapper
            withoutActionButtons={!showCancelButton && !actionButton}
          >
            {children}
          </S.ChildrenWrapper>

          {(showCancelButton || actionButton) && (
            <S.ActionButtonWrapper>
              {showCancelButton && (
                <S.Close>
                  <S.CloseButton aria-label="Close">
                    {cancelButtonTitle}
                  </S.CloseButton>
                </S.Close>
              )}

              {actionButton && actionButton}
            </S.ActionButtonWrapper>
          )}
        </S.Content>
      </S.Portal>
    </S.Root>
  );
};
