import { createStitches, defaultThemeMap } from '@stitches/react';

import { breakpoints } from './media';
import { theme as myTheme } from './theme';

export const {
  styled,
  css,
  keyframes,
  getCssText,
  theme,
  createTheme,
  config,
  globalCss,
} = createStitches({
  themeMap: {
    ...defaultThemeMap,
    borderTopLeftRadius: 'borderRadius',
    borderTopRightRadius: 'borderRadius',
    borderBottomLeftRadius: 'borderRadius',
    borderBottomRightRadius: 'borderRadius',
    borderRadius: 'borderRadius',
    border: 'borders',
    borderWidth: 'borders',
    fontStyle: 'fontStyles',
    outlineColor: 'colors',
    borderColor: 'colors',
  },
  theme: myTheme,
  utils: {
    marginX: (value: string) => ({
      marginLeft: value,
      marginRight: value,
    }),
    marginY: (value: string) => ({
      marginTop: value,
      marginBottom: value,
    }),
    paddingX: (value: string) => ({
      paddingLeft: value,
      paddingRight: value,
    }),
    paddingY: (value: string) => ({
      paddingTop: value,
      paddingBottom: value,
    }),
  },
  media: {
    isDesktop: `(min-width: ${breakpoints.large}px)`,

    huge: `(max-width: ${breakpoints.huge}px)`,
    large: `(max-width: ${breakpoints.large}px)`,

    medium: `(max-width: ${breakpoints.medium}px)`,
    small: `(max-width: ${breakpoints.small}px)`,
    xsmall: `(max-width: ${breakpoints.xsmall}px)`,
  },
});
