import { keyframes } from '@stitches/react';

export const slideDown = keyframes({
  from: {
    height: 0,
  },
  to: {
    minHeight: '44px',
  },
});

export const slideUp = keyframes({
  from: {
    minHeight: '44px',
  },
  to: {
    height: 0,
  },
});
