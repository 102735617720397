import { type ComponentProps } from 'react';

import { type ProgressStepValue } from '../ProgressStep';

import * as S from './styles';

interface StatusCheckProps extends ComponentProps<typeof S.StatusCheck> {
  progressWidth: string | undefined;
  index: number;
  label: ProgressStepValue['label'];
  /**
   * Integer value like percentage
   * ex: 10 equals 10%
   */
  progressValue: ProgressStepValue['progressValue'];
  isChecked: boolean;
  isFirstItem: boolean;
  leftMarginForTheMiddleItem: number;
}

export const StatusCheck = ({
  progressWidth,
  index,
  label,
  progressValue,
  isChecked,
  isFirstItem,
  leftMarginForTheMiddleItem,
}: StatusCheckProps) => {
  if (!progressWidth) return null;

  const isLastStatusCheck = progressValue === 100;

  const marginResult = isLastStatusCheck
    ? `calc(${progressWidth} - ${S.statusCheckWidth})`
    : leftMarginForTheMiddleItem;

  const marginLeft = isFirstItem ? undefined : marginResult;

  return (
    <S.StatusCheck css={{ marginLeft }} isChecked={isChecked}>
      {index}
      <S.StatusCheckLabel>{label}</S.StatusCheckLabel>
    </S.StatusCheck>
  );
};
