import { type IconProps } from '.';

export const WarningIcon = ({ color = '#8F9BB3', ...props }: IconProps) => {
  return (
    <svg
      className="warning-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="16"
      viewBox="0 0 19 16"
      fill="none"
      {...props}
    >
      <path
        d="M18.1333 11.9188L11.7416 1.31885C11.4832 0.91453 11.1271 0.58179 10.7062 0.3513C10.2853 0.12081 9.81311 0 9.33329 0C8.85342 0 8.38128 0.12081 7.96039 0.3513C7.5395 0.58179 7.18341 0.91453 6.92496 1.31885L0.533293 11.9188C0.307506 12.2952 0.184785 12.7244 0.177471 13.1633C0.170157 13.6021 0.278507 14.0351 0.491633 14.4188C0.738023 14.8507 1.09467 15.2095 1.52513 15.4584C1.95558 15.7073 2.4444 15.8374 2.94163 15.8355H15.725C16.2189 15.8408 16.7055 15.7157 17.1357 15.4728C17.5658 15.2299 17.9243 14.8779 18.175 14.4522C18.3943 14.0645 18.5061 13.6252 18.4988 13.1798C18.4915 12.7344 18.3653 12.2991 18.1333 11.9188ZM16.7166 13.6272C16.6165 13.7993 16.4711 13.9408 16.2963 14.0363C16.1215 14.1318 15.9239 14.1776 15.725 14.1688H2.94163C2.74264 14.1776 2.54506 14.1318 2.37026 14.0363C2.19546 13.9408 2.05011 13.7993 1.94996 13.6272C1.87682 13.5005 1.83831 13.3568 1.83831 13.2105C1.83831 13.0642 1.87682 12.9205 1.94996 12.7938L8.34996 2.18552C8.46496 2.03361 8.6136 1.91041 8.78421 1.82558C8.95482 1.74075 9.14276 1.69661 9.33329 1.69661C9.52381 1.69661 9.71181 1.74075 9.88241 1.82558C10.053 1.91041 10.2016 2.03361 10.3166 2.18552L16.7083 12.7855C16.7842 12.9124 16.8251 13.0573 16.8265 13.2052C16.828 13.3531 16.7901 13.4988 16.7166 13.6272Z"
        fill={color}
      />
      <path
        d="M9.33331 12.5022C9.79361 12.5022 10.1667 12.1291 10.1667 11.6688C10.1667 11.2086 9.79361 10.8355 9.33331 10.8355C8.87309 10.8355 8.5 11.2086 8.5 11.6688C8.5 12.1291 8.87309 12.5022 9.33331 12.5022Z"
        fill={color}
      />
      <path
        d="M9.33331 5.0022C9.11232 5.0022 8.90036 5.08999 8.74407 5.24627C8.58779 5.40256 8.5 5.61452 8.5 5.83553V9.16891C8.5 9.38991 8.58779 9.60181 8.74407 9.75811C8.90036 9.91441 9.11232 10.0022 9.33331 10.0022C9.55431 10.0022 9.76631 9.91441 9.92261 9.75811C10.0789 9.60181 10.1667 9.38991 10.1667 9.16891V5.83553C10.1667 5.61452 10.0789 5.40256 9.92261 5.24627C9.76631 5.08999 9.55431 5.0022 9.33331 5.0022Z"
        fill={color}
      />
    </svg>
  );
};
