import { type IconProps } from '.';

export const InvoiceIcon = ({ color = '#8F9BB3', ...props }: IconProps) => {
  return (
    <svg
      className="invoice-icon"
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.0002 9.6667H4.00016C3.82335 9.6667 3.65378 9.7369 3.52876 9.8619C3.40373 9.987 3.3335 10.1565 3.3335 10.3334C3.3335 10.5102 3.40373 10.6797 3.52876 10.8048C3.65378 10.9298 3.82335 11 4.00016 11H8.0002C8.177 11 8.3465 10.9298 8.4716 10.8048C8.5966 10.6797 8.6668 10.5102 8.6668 10.3334C8.6668 10.1565 8.5966 9.987 8.4716 9.8619C8.3465 9.7369 8.177 9.6667 8.0002 9.6667Z"
        fill={color}
      />
      <path
        d="M4.00016 8.33333H6.00016C6.17697 8.33333 6.34654 8.2631 6.47157 8.13807C6.59659 8.01305 6.66683 7.84348 6.66683 7.66667C6.66683 7.48986 6.59659 7.32029 6.47157 7.19526C6.34654 7.07024 6.17697 7 6.00016 7H4.00016C3.82335 7 3.65378 7.07024 3.52876 7.19526C3.40373 7.32029 3.3335 7.48986 3.3335 7.66667C3.3335 7.84348 3.40373 8.01305 3.52876 8.13807C3.65378 8.2631 3.82335 8.33333 4.00016 8.33333Z"
        fill={color}
      />
      <path
        d="M11.1598 4.55331L7.53317 0.553311C7.47084 0.484251 7.39475 0.429001 7.30978 0.391111C7.22482 0.353221 7.13287 0.333531 7.03984 0.333311H2.37317C2.15167 0.330671 1.93182 0.371681 1.72617 0.454021C1.52052 0.536351 1.33311 0.658381 1.17463 0.813151C1.01614 0.967911 0.889704 1.15238 0.802524 1.35602C0.715334 1.55966 0.669114 1.77848 0.666504 1.99998V12C0.669114 12.2215 0.715334 12.4403 0.802524 12.6439C0.889704 12.8476 1.01614 13.032 1.17463 13.1868C1.33311 13.3416 1.52052 13.4636 1.72617 13.5459C1.93182 13.6283 2.15167 13.6693 2.37317 13.6666H9.6265C9.848 13.6693 10.0679 13.6283 10.2735 13.5459C10.4792 13.4636 10.6666 13.3416 10.825 13.1868C10.9835 13.032 11.11 12.8476 11.1972 12.6439C11.2843 12.4403 11.3306 12.2215 11.3332 12V4.99998C11.3327 4.83475 11.271 4.67558 11.1598 4.55331ZM7.33317 2.33331L9.1598 4.33331H7.8265C7.75669 4.3291 7.68842 4.31102 7.62566 4.28013C7.56291 4.24925 7.50694 4.20617 7.46102 4.15342C7.41509 4.10067 7.38014 4.0393 7.35819 3.97289C7.33624 3.90649 7.32773 3.83637 7.33317 3.76664V2.33331ZM9.6265 12.3333H2.37317C2.32675 12.336 2.28026 12.3295 2.23635 12.3142C2.19245 12.2989 2.15199 12.2751 2.11731 12.2441C2.08263 12.2132 2.0544 12.1757 2.03424 12.1338C2.01408 12.0919 2.00239 12.0464 1.99984 12V1.99998C2.00239 1.95355 2.01408 1.90809 2.03424 1.86619C2.0544 1.82429 2.08263 1.78678 2.11731 1.75582C2.15199 1.72485 2.19245 1.70103 2.23635 1.68573C2.28026 1.67043 2.32675 1.66394 2.37317 1.66664H5.99984V3.76664C5.98907 4.25773 6.173 4.73313 6.51148 5.08909C6.84996 5.44505 7.3155 5.65268 7.8065 5.66664H9.9998V12C9.9973 12.0464 9.9856 12.0919 9.9654 12.1338C9.9453 12.1757 9.917 12.2132 9.8824 12.2441C9.8477 12.2751 9.8072 12.2989 9.7633 12.3142C9.7194 12.3295 9.6729 12.336 9.6265 12.3333Z"
        fill={color}
      />
    </svg>
  );
};
